import { Button, Collapse, DialogActions, DialogContent, Divider, Grid, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { AutocompleteText2, Dialog, DialogText, LoadButton, TextField, Loading } from 'src/components'
import { useApi, deleteNoValue, setFormInEmpty, useStateManage } from 'src/util/'

const SCI_WS_REQ = yup.object({
  sciws_id: yup.string().trim().required('SCI-WS設定名は必須項目です。'),
  sciws_method: yup.string().trim().required('WSメソッドは必須項目です。'),
  sciws_args: yup
    .string()
    .required('リクエストは必須項目です。')
    .test('', 'JSON形式の不備があります。', (v) => {
      try {
        return JSON.parse(v)
      } catch {
        return false
      }
    })
})

const SciWsRequest = ({ dialog, setDialog, searchVals }) => {
  console.log('SciWsRequest')
  const request = useApi()
  const { state, setObject, setValue, reset } = useStateManage({
    expanded: false,
    settings: [],
    method: [''],
    methodArgs: {}
  })

  const form = useForm({
    defaultValues: { sciws_id: '', sciws_method: '', sciws_args: '', response: '' },
    resolver: yupResolver(SCI_WS_REQ)
  })

  const onSubmit = (values) => {
    request.setOption({ clearMessage: true })
    request.post(
      '/sciws/request',
      {
        sciws_id: values.sciws_id,
        sciws_method: values.sciws_method,
        sciws_args: deleteNoValue(JSON.parse(values.sciws_args))
      },
      ({ body }) => {
        form.setValue('response', JSON.stringify(body, null, '  '))
      },
      (res) => {
        console.log(res)
        form.setValue('response', JSON.stringify(res.body?.detail, null, '  '))
      }
    )
  }

  // const handleEeProfChange = (e) => {
  //   const eeProfileName = e.target.value
  //   const eeProfile = state.ee_profiles.find((elem) => elem.ee_profile_name === eeProfileName)
  //   setObject({ available_cert_profiles: eeProfile.available_cert_profiles })
  //   form.setValue('ee_profile_name', eeProfileName)
  //   form.setValue('cert_profile_name', eeProfile.available_cert_profiles[0].name)
  // }

  const close = {
    dialog: () => setDialog({ open: false }),
    form: () => {
      setObject({ expanded: false })
      form.reset()
    }
  }

  const handleMethod = (e, value) => {
    const sciws_method = value
    if (!sciws_method) {
      setObject({ expanded: false })
      setFormInEmpty(form, ['sciws_method', 'sciws_args', 'response'])
      return
    }
    const args = state.methodArgs[sciws_method]
    if (!args) return
    const sciws_args = {}
    for (let i = 0; i < args.length; i++) {
      const arg = args[i]
      sciws_args[arg.arg_name] = arg.is_required === true ? arg.type : ''
    }
    setObject({ expanded: true })
    form.setValue('sciws_method', sciws_method, { shouldValidate: true })
    form.setValue('sciws_args', JSON.stringify(sciws_args, null, '  '), { shouldValidate: true })
    form.setValue('response', '')
  }

  useEffect(() => {
    if (!dialog.open) return
    request.setOption({ loading: 'load2' })
    request.get('/sciws/wsSetting', null, async ({ body }) => {
      const settings = [...body]
      await request.get('/sciws/methodArgs', null, (res) => {
        const method = Object.keys(res.body)
        method.push('')
        setObject({ settings, method, methodArgs: res.body })
      })
    })
  }, [dialog.open])

  return (
    <Dialog title="SCI-WSへのリクエスト" open={dialog.open} close={close} maxWidth="lg">
      <Loading name="load2" sx={{ top: '-34px' }} initialDisplay />
      <DialogContent sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField select form={form} name="sciws_id" label="SCI-WS設定名*" autoFocus>
              <MenuItem key={0} value="">
                {'　'}
              </MenuItem>
              {state.settings.map((item) => (
                <MenuItem key={item.sciws_id} value={`${item.sciws_id}`}>
                  {item.sciws_name} {item.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutocompleteText2
              form={form}
              options={state.method}
              name="sciws_method"
              label="WSメソッド*"
              onChange={handleMethod}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider variant="middle" />

      <Collapse in={state.expanded} timeout="auto" unmountOnExit>
        <DialogContent sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                form={form}
                name="sciws_args"
                label="リクエスト（JSON）"
                spellCheck="false"
                multiline
                fullWidth
                rows={15}
                inputProps={{ style: { fontSize: 'small' }, maxLength: 10000 }}
                InputLabelProps={{ shrink: true }}
                size="middle"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                form={form}
                name="response"
                label="レスポンス"
                spellCheck="false"
                multiline
                fullWidth
                rows={15}
                inputProps={{ style: { fontSize: 'small' }, maxLength: 10000, readOnly: 1 }}
                InputLabelProps={{ shrink: true }}
                size="middle"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider variant="middle" />
      </Collapse>

      <DialogActions>
        <Button
          id="cancel"
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <LoadButton type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </LoadButton>
      </DialogActions>
    </Dialog>
  )
}

SciWsRequest.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func,
  searchVals: PropTypes.object
}

export default SciWsRequest

// <DialogContent sx={{ p: 2 }}>
//         <DialogText sx={{ mb: 2 }}>
//           発行する証明書情報を入力してください。
//         </DialogText>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <TextField select form={form} name="ca_name" label="CA名*" autoFocus>
//               {state.cas.map((item) => (
//                 <MenuItem key={item.ca_id} value={item.ca_name}>
//                   {item.ca_name}
//                 </MenuItem>
//               ))}
//             </TextField>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField form={form} name="ee_username" label="EEユーザ名" />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField select form={form} name="ee_profile_name" label="EEプロファイル"
//               onChange={handleEeProfChange}
//             >
//               {state.ee_profiles.map((item) => (
//                 <MenuItem key={item.ee_id} value={item.ee_profile_name}>
//                   {item.ee_profile_name}
//                 </MenuItem>
//               ))}
//             </TextField>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField select form={form} name="cert_profile_name" label="証明書プロファイル">
//               {state.available_cert_profiles.map((item) => (
//                 <MenuItem key={item.id} value={item.name}>
//                   {item.name}
//                 </MenuItem>
//               ))}
//             </TextField>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField form={form} name="subject_dn" label="DN*"
//               inputProps={{ maxLength: 400 }}
//               autoComplete="ON"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField form={form} name="subject_alt_name" label="SubjectAltName"
//               inputProps={{ maxLength: 250 }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Divider />
//             <DialogText sx={{ mt: 2 }}>
//               ＊オプション項目 - 一部EJBCAプロファイルの設定が必要です。
//             </DialogText>
//           </Grid>
//           <Grid item xs={6}>
//             <TextField form={form} name="start_time"
//               label="有効期限（開始）" type="date"
//               InputLabelProps={{ shrink: true }}
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField form={form} name="end_time"
//               label="有効期限（終了）" type="date"
//               InputLabelProps={{ shrink: true }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField select form={form} name="key_store_type"
//               label="キーストアタイプ"
//             >
//               <MenuItem value="USERGENERATED">証明書発行のみ</MenuItem>
//               <MenuItem value="P12">PKCS12ダウンロード</MenuItem>
//             </TextField>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField select form={form} name="algorithm_strength"
//               label="アルゴリズム-強度"
//             >
//               <MenuItem value="RSA,2048">RSA-2048</MenuItem>
//               <MenuItem value="RSA,4096">RSA-4096</MenuItem>
//               <MenuItem value="RSA,8192">RSA-8192</MenuItem>
//               <MenuItem value="ECDSA,P-256">ECDSA-P-256</MenuItem>
//               <MenuItem value="ECDSA,P-384">ECDSA-P-384</MenuItem>
//               <MenuItem value="ECDSA,P-521">ECDSA-P-521</MenuItem>
//             </TextField>
//           </Grid>
//         </Grid>
//       </DialogContent>
