import { Button, DialogActions, DialogContent } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { memo, useEffect } from 'react'
import { TextField, Dialog, DialogText, MoreIconButton, AutocompleteText2 } from 'src/components'
import {
  useApi,
  useStateManage,
  setFormInEmpty,
  useUser,
  extractKeys,
  isSuperContract,
  setFormInit,
  useContract
} from 'src/util/'

const CONTRACT_DISP_NAME = yup.object({
  contract_disp_name: yup.string().required('契約IDは必須項目です。')
})
const PERMISSION_GROUP = yup.object({
  contract_id: yup.string().required('契約IDは必須項目です。'),
  permission_group_name: yup.string().trim().required('権限グループ名は必須項目です。'),
  description: yup.string()
})

const PermissionGroupMore = memo(({ init, selectedGroup }) => {
  console.log('PermissionGroupMore')
  const request = useApi()
  const { state, setObject } = useStateManage({ 1: false, 2: false, 3: false })
  const contract = useContract()
  const user = useUser()

  const handleDelete = (sg) => {
    request.delete(
      '/permissionGroup',
      { permission_group_id: sg.permission_group_id, contract_id: sg.contract_id },
      () => {
        contract.clearPermissionGroup()
        document.getElementById('pageReflesh').click()
      }
    )
  }

  const itemList = [
    { itemName: '作成', call: () => setObject({ 1: true }) },
    { itemName: '編集', call: () => setObject({ 2: true }), disabled: () => selectedGroup.permission_group_id === '' },
    {
      itemName: '削除',
      call: () => handleDelete(selectedGroup),
      disabled: () => selectedGroup.permission_group_id === ''
    }
  ]

  const form = useForm({
    defaultValues: { contract_disp_name: null, contract_id: null, permission_group_name: '', description: '' },
    // スーパー契約の場合、画面表示用の契約ID未選択時にバリデーションエラー
    resolver: user.isSuper() ? yupResolver(CONTRACT_DISP_NAME.concat(PERMISSION_GROUP)) : yupResolver(PERMISSION_GROUP)
  })

  const onSubmitCreate = (values) => {
    request.post(
      '/permissionGroup',
      extractKeys(values, ['contract_id', 'permission_group_name', 'description']),
      () => {
        setObject({ 1: false })
        init({ createdFlg: true, contractDispName: values.contract_disp_name })
        form.reset()
      }
    )
  }

  const form2 = useForm({
    defaultValues: {
      contract_disp_name: null,
      contract_id: null,
      permission_group_id: '',
      permission_group_name: '',
      description: ''
    },
    resolver: yupResolver(PERMISSION_GROUP)
  })

  const onSubmitEdit = (values) => {
    request.patch(
      '/permissionGroup',
      extractKeys(values, ['contract_id', 'permission_group_id', 'permission_group_name', 'description']),
      () => {
        setObject({ 2: false })
        init({
          editedFlg: true,
          contractDispName: values.contract_disp_name,
          permissionGroupId: values.permission_group_id
        })
      }
    )
  }

  const initPermissionGroupCreate = () => {
    // スーパー契約でない場合、契約IDの初期値を設定
    !user.isSuper() && form.setValue('contract_id', user.contractId)
  }

  const initPermissionGroupEdit = () => {
    form2.setValue('contract_id', selectedGroup.contract_id)
    form2.setValue('contract_disp_name', selectedGroup.contract_disp_name)
    form2.setValue('permission_group_id', selectedGroup.permission_group_id)
    form2.setValue('permission_group_name', selectedGroup.permission_group_name)
    form2.setValue('description', selectedGroup.description)
  }
  useEffect(initPermissionGroupEdit, [selectedGroup.permission_group_id])
  useEffect(initPermissionGroupCreate, [state[1]])

  const handleContract = (e, value) => {
    const contractDispName = contract.getContractDispList().find((s) => s === value)
    if (!contractDispName) return

    form.setValue('contract_disp_name', value)
    form.setValue('contract_id', value.substring(0, value.indexOf(':')))
  }

  return (
    <div>
      <MoreIconButton itemList={itemList} />

      <Dialog
        title="新規権限グループの作成"
        open={state[1]}
        close={{ dialog: () => setObject({ 1: false }), form: form.reset }}
        maxWidth="md"
      >
        <DialogContent>
          <DialogText text="作成する権限グループ情報を入力してください。" />
          {user.isSuper() && (
            <AutocompleteText2
              sx={{ mt: '20px' }}
              form={form}
              options={contract.getContractDispList()}
              name="contract_disp_name"
              label="契約*"
              onChange={handleContract}
              autoFocus
            />
          )}
          <TextField
            form={form}
            sx={{ mt: '20px' }}
            name="permission_group_name"
            label="権限グループ名*"
            autoFocus={!user.isSuper()}
            inputProps={{ maxLength: 64 }}
            // margin="normal"
          />
          <TextField
            form={form}
            sx={{ mt: '20px' }}
            name="description"
            label="説明文"
            inputProps={{ maxLength: 64 }}
            // margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setObject({ 1: false })
              form.reset()
            }}
          >
            キャンセル
          </Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmitCreate)}>
            作成
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        title="権限グループの編集"
        open={state[2]}
        close={{ dialog: () => setObject({ 2: false }), form: form.reset }}
        maxWidth="md"
      >
        <DialogContent>
          <DialogText>{selectedGroup.permission_group_name}の編集内容を入力してください。</DialogText>
          {user.isSuper() && (
            <TextField
              form={form2}
              sx={{ mt: '20px' }}
              name="contract_disp_name"
              label="契約ID*"
              disabled
              inputProps={{ maxLength: 64 }}
            />
          )}
          <TextField
            form={form2}
            sx={{ mt: '20px' }}
            name="permission_group_name"
            label="権限グループ名*"
            inputProps={{ maxLength: 64 }}
            autoFocus
          />
          <TextField
            form={form2}
            sx={{ mt: '20px' }}
            name="description"
            label="説明文"
            inputProps={{ maxLength: 64 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setObject({ 2: false })
              initPermissionGroupEdit()
            }}
          >
            キャンセル
          </Button>
          <Button type="submit" onClick={form2.handleSubmit(onSubmitEdit)}>
            変更
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

PermissionGroupMore.propTypes = {
  init: PropTypes.func,
  selectedGroup: PropTypes.object
}

export default PermissionGroupMore
