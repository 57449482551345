// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider, Input, GlobalStyles } from '@mui/material'
// import GlobalStyles from './contents/_framework/GlobalStyles'
import { AppProvider } from 'src/util/appContext'
import theme from './theme'
import App from './App'

// 開発環境以外console.logの出力を停止
process.env.NODE_ENV !== 'development' && (console.log = () => {})
// const {primary, secondary, background} = theme.palette

const root = createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    {/* <StyledEngineProvider injectFirst> */}
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0
          },
          html: {
            // '-webkit-font-smoothing': 'antialiased',
            // '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
          },
          body: {
            fontFamily:
              'Verdana, Roboto, "Droid Sans", "メイリオ", Meiryo, "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif',
            backgroundColor: '#000',
            height: '100%',
            width: '100%'
          },
          a: {
            textDecoration: 'none'
          },
          input: {
            '&::-ms-reveal': { display: 'none' } // Edgeのパスワード確認ボタン非表示
          },
          table: {
            border: '0px solid rgba(224, 224, 224, 1)'
          },
          th: {
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontSize: '0.875rem',
            border: '0px solid rgba(224, 224, 224, 1)'
          },
          td: {
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontSize: '0.875rem',
            border: '0px solid rgba(224, 224, 224, 1)'
          },
          pre: {
            fontFamily: 'Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono'
          },
          '#root': {
            height: '100%',
            width: '100%'
          },
          '.load-show': {
            zIndex: '999!important',
            opacity: '1!important'
          },
          '.load-hide': {
            zIndex: '-1!important',
            opacity: '0!important'
          }
          // '.hover': {
          //   '&:hover': {
          //     backgroundColor: '#e8f0fe',
          //   }
          // },
        }}
      />
      <AppProvider>
        <App />
      </AppProvider>
    </ThemeProvider>
    {/* </StyledEngineProvider> */}
  </BrowserRouter>
)

// ReactDOM.createRoot(
//     <BrowserRouter>
//       <StyledEngineProvider injectFirst>
//         <ThemeProvider theme={theme}>
//           <GlobalStyles styles={{
//             '*': {
//               boxSizing: 'border-box',
//               margin: 0,
//               padding: 0,
//             },
//             html: {
//               // '-webkit-font-smoothing': 'antialiased',
//               // '-moz-osx-font-smoothing': 'grayscale',
//               height: '100%',
//               width: '100%'
//             },
//             body: {
//               fontFamily: 'Verdana, Roboto, "Droid Sans", "メイリオ", Meiryo, "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif',
//               backgroundColor: '#000',
//               height: '100%',
//               width: '100%'
//             },
//             a: {
//               textDecoration: 'none'
//             },
//             input: {
//               '&::-ms-reveal': { display: 'none' }, // Edgeのパスワード確認ボタン非表示
//             },
//             table: {
//               border: '0px solid rgba(224, 224, 224, 1)',
//             },
//             th: {
//               fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
//               fontSize: '0.875rem',
//               border: '0px solid rgba(224, 224, 224, 1)',
//             },
//             td: {
//               fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
//               fontSize: '0.875rem',
//               border: '0px solid rgba(224, 224, 224, 1)',
//             },
//             pre: {
//               fontFamily: 'Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono'
//             },
//             '#root': {
//               height: '100%',
//               width: '100%'
//             },
//             '.load-show': {
//               zIndex: '999!important',
//               opacity: '1!important',
//             },
//             '.load-hide': {
//               zIndex: '-1!important',
//               opacity: '0!important',
//             },
//             // '.hover': {
//             //   '&:hover': {
//             //     backgroundColor: '#e8f0fe',
//             //   }
//             // },
//           }}
//           />
//           <App />
//         </ThemeProvider>
//       </StyledEngineProvider>
//     </BrowserRouter>
//   ),
//   document.getElementById('root')
// )
