import { TableBody, TableRow, DialogContent, Grid, Table } from '@mui/material'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTable,
  StyledCell,
  StyledLinkCell,
  MoreIconButton,
  Snackbar,
  RenderingSuppress
} from 'src/components'
import { useApi, useStateManage } from 'src/util/'
import { styled } from '@mui/material/styles'

const CertTableBody = ({ list }) => {
  const request = useApi()
  const { state, setObject } = useStateManage({
    open1: false,
    open1Title: '',
    list1: [],
    open2: false
  })

  const handleDetail = (callArg) => {
    setObject({
      open1: true,
      open1Title: callArg.account_id,
      list1: [
        { th: 'コンタクト', td: callArg.contact },
        { th: '検証済みLv', td: callArg.verified_level },
        { th: '検証済みDN', td: callArg.verified_subject_dn },
        { th: '同意', td: Boolean(callArg.terms_of_service_agreed).toString() },
        { th: '同意時刻', td: callArg.agreed_at },
        { th: '登録時IP', td: callArg.initial_ip }
      ]
    })
  }

  const handleDelete = (callArg) => {
    request.delete(
      '/acme/account',
      { account_id: callArg.account_id, is_eab_delete: false, contract_id: callArg.contract_id },
      () => {
        document.getElementById('pageReflesh').click()
      }
    )
  }

  const handleReactivate = (callArg) => {
    request.post(
      '/acme/account/reactivate',
      { account_id: callArg.account_id, contract_id: callArg.contract_id },
      () => {
        document.getElementById('pageReflesh').click()
      }
    )
  }

  const handleAcmeCli = (callArg) => {
    request.get('/acme/certbotCli', { account_id: callArg.account_id }, async ({ body }) => {
      document.getElementById('MoreIconButton').focus()
      await navigator.clipboard?.writeText(body.certbot_command).then()
      setObject({ open2: true })
    })
  }

  const itemList = [
    { itemName: 'ACMEアカウント詳細', call: (callArg) => handleDetail(callArg) },
    { itemName: 'ACMEアカウント削除', call: (callArg) => handleDelete(callArg) },
    { itemName: 'ACMEアカウント再有効化', call: (callArg) => handleReactivate(callArg) },
    { itemName: 'Certbotコマンド (Copy)', call: (callArg) => handleAcmeCli(callArg) }
  ]

  return (
    <TableBody>
      {list.map((row, index) => (
        <TableRow hover key={row.account_id}>
          <StyledCell>{row.contract_id}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.account_binding}</StyledCell>
          <StyledLinkCell sx={{ wordBreak: 'break-all' }} to={`/app/acmeOrder?account_id=${row.account_id}`}>
            {row.account_id}
          </StyledLinkCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>
            {row.status}
            {row.status === 'provisional' && '（仮登録）'}
            {row.status === 'valid' && '（有効）'}
            {row.status === 'deactivated' && '（無効）'}
            {row.status === 'revoked' && '（廃止）'}
          </StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.setting_name}</StyledCell>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.updated_at}</StyledCell>
          <StyledCell id="MoreIconButton" align="center" sx={{ p: '0', height: '42px' }}>
            <MoreIconButton itemList={itemList} callArg={row} icon="horiz" sx={{ m: '-8px' }} />
          </StyledCell>
        </TableRow>
      ))}
      <Snackbar
        message="クリップボードにコピーしました。"
        open={state.open2}
        onClose={() => setObject({ open2: false })}
      />
      <RenderingSuppress show={state.open1}>
        <DialogTable
          title={`アカウントの詳細：${state.open1Title}`}
          open={state.open1}
          onClose={() => setObject({ open1: false })}
          list={state.list1}
        />
      </RenderingSuppress>
    </TableBody>
  )
}

CertTableBody.propTypes = {
  list: PropTypes.array.isRequired
}

export default CertTableBody
