import { Grid, Button, DialogActions, DialogContent, Divider, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { memo, useReducer, useEffect } from 'react'
import { Dialog, DialogText, RenderingSuppress, MoreIconButton, LoadButton, TextField } from 'src/components'
import { useApi, deleteNoValue, useStateManage, useUser } from 'src/util/'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { AcmeSettingRegister, AcmeSettingEdit } from './AcmeSetting'

export function AccountSearchMore() {
  console.log('AccountSearch')
  const sm = useStateManage({
    1: { open: false },
    2: { open: false },
    3: { open: false }
  })
  const user = useUser()

  const itemList = [
    {
      itemName: 'ACME設定の登録',
      call: () => sm.setObject({ 1: { open: user.isSuper() } }),
      disabled: () => !user.isSuper()
    },
    {
      itemName: 'ACME設定の変更・削除',
      call: () => sm.setObject({ 2: { open: user.isSuper() } }),
      disabled: () => !user.isSuper()
    },
    { itemName: 'divider1', divider: true },
    {
      itemName: 'ACMEアカウントの作成',
      call: () => sm.setObject({ 3: { open: true } })
    }
  ]

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <RenderingSuppress show={sm.state[1].open}>
        <AcmeSettingRegister dialog={sm.state[1]} setDialog={(obj) => sm.setObject({ 1: obj })} />
      </RenderingSuppress>
      <RenderingSuppress show={sm.state[2].open}>
        <AcmeSettingEdit dialog={sm.state[2]} setDialog={(obj) => sm.setObject({ 2: obj })} />
      </RenderingSuppress>
      <RenderingSuppress show={sm.state[3].open}>
        <AcmeAccountCreate dialog={sm.state[3]} setDialog={(obj) => sm.setObject({ 3: obj })} />
      </RenderingSuppress>
    </>
  )
}

const ACCT_CREATE_FORM = yup.object({
  user_id: yup.string().trim().required('ユーザIDは必須項目です。'),
  setting_id: yup.string().required('ACME設定IDは必須項目です。'),
  verified_subject_dn: yup.string().when('verified_level', ([verified_level], s) => {
    return verified_level !== '' ? s.trim().required('検証済みSubjectDNは、OV | IV | EV 時に必須項目です。') : s
  })
})

function AcmeAccountCreate({ dialog, setDialog }) {
  console.log('AcmeAccountCreate')
  const request = useApi({ clearMessage: true })
  const { state, setObject } = useStateManage({ settings: [] })

  const form = useForm({
    defaultValues: { user_id: '', setting_id: '', verified_level: '', verified_subject_dn: '' },
    resolver: yupResolver(ACCT_CREATE_FORM)
  })

  const onSubmit = async (values) => {
    deleteNoValue(values)
    await request.post('/acme/account', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const close = {
    dialog: () => setDialog({ open: false }),
    form: form.reset
  }

  useEffect(() => {
    if (!dialog.open) return
    request.get('/acme/acmeSetting', null, ({ body }) => {
      setObject({ settings: [...body] })
    })
  }, [dialog.open])

  return (
    <Dialog title="ACMEアカウントの作成" open={dialog.open} close={close} maxWidth="lg">
      <DialogContent sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogText text="ACMEアカウントを紐付ける有効なユーザIDを入力してください。" />
          </Grid>
          <Grid item xs={6}>
            <TextField form={form} name="user_id" label="ユーザID*" autoFocus inputProps={{ maxLength: 32 }} />
            {/* <AutocompleteText2 setValue={form.setValue} name="user_id"
              label="ユーザID"
              reqParams={{ method: 'get', path: '/selectGroup', sendData: { selectName: 'ejbcas.log_inspection.eventStatus' } }}
              optionPropName="value"
            /> */}
          </Grid>
          <Grid item xs={6}>
            <TextField select form={form} name="setting_id" label="ACME設定名*">
              {state.settings.map((item) => (
                <MenuItem key={item.setting_id} value={`${item.setting_id}`}>
                  {item.setting_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select form={form} name="verified_level" label="検証済みレベル">
              <MenuItem key={0} value="">
                {'　'}
              </MenuItem>
              <MenuItem key={2} value="OV">
                OV（Organization Validation）
              </MenuItem>
              <MenuItem key={3} value="IV">
                IV（Individual Validation）
              </MenuItem>
              <MenuItem key={4} value="EV">
                EV（Extended Validation）
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              form={form}
              name="verified_subject_dn"
              label="検証済みSubjectDN"
              inputProps={{ maxLength: 250 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Button
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <LoadButton type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </LoadButton>
      </DialogActions>
    </Dialog>
  )
}

AcmeAccountCreate.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func
}
