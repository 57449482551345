import {
  Button,
  DialogActions,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent
} from '@mui/material'
import { useEffect } from 'react'
import {
  MoreIconButton,
  StyledCell,
  HeaderBox,
  FooterBox,
  Pagination,
  SortCell,
  Loading,
  Dialog,
  IconText,
  DialogText
} from 'src/components'
import { useApi, useStateManage, usePageControl } from 'src/util/'
import PropTypes from 'prop-types'
import ContractTableMore from './ContractTableMore'

const DeleteDialog = ({ dialog, setDialog }) => {
  const request = useApi()
  const submitDelete = (callArg) => {
    request.delete('/contract', { contract_id: callArg.contract_id }, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  return (
    <Dialog
      name="契約削除"
      title={<IconText.Warning text="契約削除" />}
      open={dialog.deleteDialog}
      close={() => setDialog({ deleteDialog: false })}
      onClose={() => setDialog({ deleteDialog: false })}
      maxWidth="sm"
    >
      <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
        <DialogText sx={{ mb: 2, lineHeight: 1.8 }}>
          「{dialog.selectedContract.contract_name}」に紐づく全ユーザも削除されます。
          <br />
          契約を削除してもよろしいですか？
        </DialogText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setDialog({ deleteDialog: false })
          }}
        >
          キャンセル
        </Button>
        <Button type="submit" onClick={() => submitDelete(dialog.selectedContract)}>
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}
DeleteDialog.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func
}

const ContractTable = () => {
  console.log('ContractTable')
  const request = useApi()
  const pc = usePageControl()
  const { state, setObject } = useStateManage({
    dataTotal: 0,
    pageTotal: 0,
    pageNo: 0,
    list: [],
    retryDialog: false,
    editDialog: false,
    selectedContract: {},
    deleteDialog: false
  })

  const search = (pageNo, values) => {
    request.setOption({ loading: 'load1' })
    values.pageNo = pageNo
    request.get('/contract/page', values, ({ body }) => {
      setObject({
        dataTotal: body.page.dataTotal,
        pageTotal: body.page.pageTotal,
        pageNo,
        list: [...body.rows]
      })
    })
  }

  useEffect(() => {
    pc.initSearch(search, () => setObject({ retryDialog: true }))
  }, [])

  const handleSort = (_sort) => {
    if (state.dataTotal === 0) return
    pc.handleSort(_sort, () => setObject({ retryDialog: true }))
  }

  const itemList = [
    {
      itemName: '編集',
      call: (callArg) => {
        setObject({ editDialog: true, selectedContract: callArg })
      }
    },
    {
      itemName: '削除',
      call: (callArg) => {
        setObject({ deleteDialog: true, selectedContract: callArg })
      }
    }
  ]
  return (
    <Card>
      <Loading name="load1" sx={{ top: '10px' }} />
      <HeaderBox title="契約検索結果">{state.dataTotal && '計' + state.dataTotal + '件'}</HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCell name="contract_id" firstSortDescending sort={pc.sort} onClick={handleSort}>
                No
              </SortCell>
              <SortCell sx={{ minWidth: 160 }} name="contract_name" sort={pc.sort} onClick={handleSort}>
                契約名
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="contract_user_prefix" sort={pc.sort} onClick={handleSort}>
                プレフィックス
              </SortCell>
              <StyledCell sx={{ minWidth: 160 }}>住所</StyledCell>
              <StyledCell sx={{ minWidth: 120 }}>電話番号</StyledCell>
              <StyledCell sx={{ minWidth: 160, width: 160 }}>説明</StyledCell>
              <SortCell sx={{ minWidth: 110, width: 160 }} name="updated_at" sort={pc.sort} onClick={handleSort}>
                更新日
              </SortCell>
              <StyledCell sx={{ minWidth: 110 }}>更新者</StyledCell>
              <StyledCell sx={{ width: 24 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {state.list.map((row, index) => (
              <TableRow hover key={row.contract_id}>
                <StyledCell>{row.contract_id}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.contract_name}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.contract_user_prefix}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.address}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.tel_no}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.description}</StyledCell>
                <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.updated_at}</StyledCell>
                <StyledCell>{row.updater}</StyledCell>
                <StyledCell align="center" sx={{ p: '0', height: '42px' }}>
                  <MoreIconButton itemList={itemList} callArg={row} icon="horiz" sx={{ m: '-8px' }} />
                </StyledCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FooterBox sx={{ justifyContent: 'center' }}>
        <Pagination
          state={state}
          setFormRequest={pc.setFormRequest}
          closeRetryDialog={() => setObject({ retryDialog: false })}
        />
      </FooterBox>
      <ContractTableMore dialog={state} setDialog={setObject} />
      <DeleteDialog dialog={state} setDialog={setObject} />
    </Card>
  )
}

export default ContractTable
