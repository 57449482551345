import { Button, DialogActions, DialogContent, MenuItem, Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { memo, useState, useEffect } from 'react'
import * as yup from 'yup'
import { Dialog, DialogText, TextField, MoreIconButton, IconText, RenderingSuppress } from 'src/components'
import { isSuperContract, useApi, useContract, useStateManage, useUser } from 'src/util/'

const USER_ADD = yup.object({
  user_id: yup.string().trim().required('IDは必須項目です。'),
  user_name: yup.string().trim().required('名前は必須項目です。'),
  mail: yup.string().nullable().email('有効なメールアドレス形式でありません。'),
  contract_id: yup.string(),
  permission_group_id: yup.string().required('権限グループは必須項目です。')
})

const UserAdd = ({ state, setState }) => {
  console.log('UserAdd')
  const request = useApi({ clearMessage: true })
  const user = useUser()
  const contract = useContract()

  const form = useForm({
    defaultValues: {
      user_id: '',
      user_name: '',
      mail: '',
      permission_group_id: '',
      contract_id: user.isSuper() ? '' : user.contractId,
      prefix: ''
    },
    resolver: yupResolver(USER_ADD)
  })

  const onSubmit = (values) => {
    request.post('/user', values, ({ body }) => {
      setState({ open: false, created: true, createdUserId: body.user_id, createdPwd: body.password })
    })
  }

  const onClose = () => {
    setState({ open: false })
    form.reset()
  }

  const setPrefix = (contractId) => {
    const obj = contract.getContractList().find((m) => m.contract_id === contractId)
    if (!obj || !obj.contract_user_prefix) {
      form.setValue('prefix', '')
      return
    }
    form.setValue('prefix', obj.contract_user_prefix)
  }

  const handleSelected = ({ target }) => {
    form.setValue('permission_group_id', '')
    form.setValue('contract_id', target.value)
    setPrefix(target.value)
    form.trigger('_render')
  }

  useEffect(() => {
    if (user.isSuper()) {
      form.setValue('prefix', ' ')
      return
    }
    setPrefix(user.contractId)
  }, [])

  return (
    <>
      <Dialog
        title="アカウント作成"
        open={state.open}
        close={{ dialog: () => setState({ open: false }), form: form.reset }}
        onClose={() => onClose()}
        maxWidth="lg"
      >
        <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
          <DialogText sx={{ mb: 2 }}>作成するアカウントの情報を入力してください。</DialogText>

          <Grid container spacing={2}>
            {isSuperContract(user.contractId) && (
              <Grid item xs={12}>
                <TextField
                  form={form}
                  name="contract_id"
                  label="契約*"
                  select
                  control={form.control}
                  onChange={handleSelected}
                >
                  {contract.getContractList().map((item) => (
                    <MenuItem key={item.contract_id} value={item.contract_id}>
                      {item.contract_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12} sm={2}>
              <TextField form={form} name="prefix" label="プレフィックス" inputProps={{ disabled: true }} />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField form={form} name="user_id" label="ID*" autoFocus />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField form={form} name="user_name" label="名前*" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField form={form} name="mail" label="メール" inputProps={{ maxLength: 64 }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField form={form} name="permission_group_id" label="権限グループ*" select control={form.control}>
                {contract.getPermissionGroupList(form.getValues('contract_id')).map((item) => (
                  <MenuItem key={item.permission_group_id} value={item.permission_group_id}>
                    {item.permission_group_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>キャンセル</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
            送信
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        title={<IconText.Success text="アカウント作成成功" />}
        open={state.created}
        backdropCloseOff
        draggableOff
        close={() => {
          setState({ created: false })
          document.getElementById('pageReflesh').click()
        }}
      >
        {`ユーザID「${state.createdUserId}」を作成しました。初期パスワードは「${state.createdPwd}」になります。`}
      </Dialog>
    </>
  )
}

UserAdd.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}

const USER_RESTORE = yup.object({
  user_id: yup.string().trim().required('IDは必須項目です。')
})

const UserRestore = ({ state, setState }) => {
  console.log('UserRestore')
  const request = useApi({ clearMessage: true })
  const form = useForm({
    resolver: yupResolver(USER_RESTORE),
    defaultValues: { user_id: '' }
  })

  const onSubmit = (values) => {
    request.post('/user/restore', values, ({ body }) => {
      setState({ open: false, restored: true, restoredUserId: body.user_id, restoredPwd: body.password })
    })
  }

  return (
    <>
      <Dialog
        title="アカウントの復元"
        open={state.open}
        close={{ dialog: () => setState({ open: false }), form: form.reset }}
        maxWidth="lg"
      >
        <DialogContent>
          <DialogText text="復元するユーザIDを入力してください。" />
          <TextField form={form} name="user_id" label="ID*" autoFocus sx={{ mt: '16px' }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setState({ open: false })
              form.reset()
            }}
          >
            キャンセル
          </Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
            復元
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        title={<IconText.Success text="アカウント復元成功" />}
        name="アカウント復元成功"
        open={state.restored}
        backdropCloseOff
        draggableOff
        close={() => {
          setState({ restored: false })
          document.getElementById('pageReflesh').click()
        }}
      >
        {`ユーザID「${state.restoredUserId}」を復元しました。初期パスワードは「${state.restoredPwd}」になります。`}
      </Dialog>
    </>
  )
}

UserRestore.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}

const UserSearchMore = memo(() => {
  console.log('UserSearchMore')
  const { state, setValue } = useStateManage({
    1: { open: false, created: false, createdUserId: '', createdPwd: '' },
    2: { open: false, restored: false, restoredUserId: '', restoredPwd: '' }
  })

  const itemList = [
    { itemName: 'ユーザ追加', call: () => setValue(1, { open: true }) },
    { itemName: 'ユーザ復元', call: () => setValue(2, { open: true }) }
  ]

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <RenderingSuppress show={state[1].open}>
        <UserAdd state={state[1]} setState={(obj) => setValue(1, obj)} />
      </RenderingSuppress>
      <RenderingSuppress show={state[2].open}>
        <UserRestore state={state[2]} setState={(obj) => setValue(2, obj)} />
      </RenderingSuppress>
    </>
  )
})

export default UserSearchMore
