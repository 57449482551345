import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { AppBar, Toolbar } from '@mui/material'
import Logo from './Logo'

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden'
}))

const MainLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  height: '100%',
  paddingTop: 64
})

// const MainLayoutContainer = styled('div')({
//   display: 'flex',
//   flex: '1 1 auto',
//   overflow: 'hidden'
// })

const MainLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'hidden'
})

// export const UserInfo = createContext()

const MainLayout = () => {
  // console.log('MainLayout: ')
  return (
    <MainLayoutRoot>
      <AppBar sx={{ backgroundColor: 'background.custom1' }} elevation={0}>
        <Toolbar sx={{ height: 64 }}>
          <Logo />
        </Toolbar>
      </AppBar>
      <MainLayoutWrapper>
        {/* <MainLayoutContainer> */}
        <MainLayoutContent>
          {/* <Outlet context={[global, render]} /> */}
          <Outlet />
        </MainLayoutContent>
        {/* </MainLayoutContainer> */}
      </MainLayoutWrapper>
    </MainLayoutRoot>
  )
}

export default MainLayout
