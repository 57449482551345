export const CALL = {
  APP: 'App',
  LOGIN: 'Login',
  SNACKBAR: 'Snackbar',
  PAGE_ALERT: 'PageAlert',
  DASHBOARD_LAYOUT: 'DashboardLayout',
  DASHBOARD_SIDEBAR: 'DashboardSidebar'
}

/* eslint-disable no-restricted-globals */
const respLocation = [location.pathname]

export class CallUtils {
  static isPathChange() {
    const previous = respLocation.shift()
    respLocation.push(location.pathname)
    if (previous !== location.pathname && location.pathname !== '/login') {
      return true
    }
    return false
  }

  static renderGlobal(curt, prev, lv) {
    const globalJson = JSON.stringify(curt.global)
    const prevJson = JSON.stringify(prev)
    if (prevJson === globalJson) return prev
    CallUtils.call(curt, curt.renderPoint)
    const clone = JSON.parse(JSON.stringify(prev))
    curt.global = clone
    return clone
  }

  static renderDiff(curt, cbName, base, diff) {
    const baseJson = JSON.stringify(base)
    const diffJson = JSON.stringify(diff)
    if (baseJson === diffJson) return base
    CallUtils.call(curt, cbName)
    const clone = JSON.parse(diffJson)
    return clone
  }

  static call(curt, cbName, args) {
    const cb = curt.callBacks[cbName]
    if (!cb) {
      console.error(`CALL [${cbName}] is not set CallBack Function`)
      return undefined
    }
    console.log(`----- Execute CallBack Function [${cbName}]`)
    return cb(args)
  }
}

export const FIELD = {
  /** 契約オブジェクトのリスト */
  CONTRACT_LIST: 'CONTRACT_LIST',
  /** 表示用の契約名（契約ID:契約名）一覧 */
  CONTRACT_DISP_LIST: 'CONTRACT_DISP_LIST',
  /** 権限グループのオブジェクトリスト */
  PERMISSION_GROUP_LIST: 'PERMISSION_GROUP_LIST',
  /** 権限グループの<contract_id, obj>Map */
  PERMISSION_GROUP_MAP: 'PERMISSION_GROUP_MAP'
}
