/* eslint-disable */
import { useState } from 'react'

export function isObject(obj) {
  return obj !== null && typeof obj === 'object' && obj.constructor === Object
}

/**
 * 単純な同一比較を行う。
 *
 * 浅いフィールドで値が一致するかを比較した結果を返す。
 * @param {*} obj1
 * @param {*} obj2
 * @return
 */
export function isEqual(obj1, obj2) {
  const obj1Array = Object.entries(obj1).sort()
  const obj2Array = Object.entries(obj2).sort()
  return JSON.stringify(obj1Array) === JSON.stringify(obj2Array)
}

/**
 * ベースオブジェクトから見たプロパティの差分があるかを判定する。
 * 差分がなければtrueを返す
 *
 * targetObjにbaseObjより多くのプロパティが存在しても、
 * baseObjのプロパティと一致していればtrueを返す。
 * 比較は浅いフィールドのみで行われる。
 *
 * @param {*} baseObj
 * @param {*} targetObj
 */
export function isEqualDiff(baseObj, targetObj) {
  if (!isObject(baseObj) || !isObject(targetObj)) return false

  for (let property in baseObj) {
    if (baseObj[property] !== targetObj[property]) {
      return false
    }
  }
  return true
}

/**
 * オブジェクトからプロパティ値がnull,undefined,空文字のものを削除する。
 * 直接プロパティを削除するため、元のオブジェクトに影響させたくない場合はクローンを生成して渡します。
 * @param {*} obj
 */
export function deleteNoValue(obj) {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === '' || obj[key] === null || obj[key] === undefined) delete obj[key]
    })
    return obj
  }
}

/**
 * ベースオブジェクトから見たプロパティの差分をマージする。
 *
 * スプレッド構文によるマージと異なり、baseObjのプロパティに対して差分の更新をかける。
 * baseObjに定義されたプロパティ以外は無視されるため、baseObjと同じプロパティ数の新規オブジェクトが返る。
 *
 * @param {*} baseObj
 * @param {*} updateObj
 * @return newObj
 */
export function mergeDiff(baseObj, updateObj) {
  const newObj = {}
  for (let property in baseObj) {
    if (updateObj[property] === undefined || updateObj[property] === null) {
      newObj[property] = baseObj[property]
      continue
    }
    newObj[property] = updateObj[property]
  }
  return newObj
}

/**
 * beforeObjとafterObjの値の差分から、更新用オブジェクトを生成して返す。
 * UPDATEしたい項目だけのオブジェクトを生成する目的で使用。
 * @return updateObj
 */
export function createUpdateObj(beforeObj, afterObj) {
  let updateObj = {}
  for (let property in beforeObj) {
    if (beforeObj[property] !== afterObj[property]) {
      updateObj[property] = afterObj[property]
    }
  }
  return updateObj
}

// export const getEnv = (envVariableName) => {
//   const envVariable = process.env[envVariableName]
//   if (!envVariable) console.log('環境変数[' + envVariableName + ']が取得できませんでした。')
//   return envVariable
// }

export function diffForm(id, name, value, response, form) {
  const initValue = response[id][name]
  const targetElem = { [name]: value }
  form[id] = { ...form[id], ...targetElem }
  if (initValue !== value) {
    form[id][name + '_diff'] = true
  } else {
    delete form[id][name + '_diff']
  }
  return [...form]
}

/**
 * formを指定のvaluesで初期化する。
 * valuesに値が指定されていない（undefined）場合やnullの場合、formのデフォルト値が使用される。
 * @param {*} form 対象のフォーム。defaultValuesに定義されているプロパティと値が初期化に利用される。
 * @param {*} values フォームの初期化で使用する値群。事前にdefaultValuesに定義されていないプロパティは無視される。
 */
export function setFormInit(form, values) {
  const defaultValues = form.formState.defaultValues
  for (let prop in defaultValues) {
    if (!values || values[prop] === null || values[prop] === undefined) {
      form.setValue(prop, defaultValues[prop])
      continue
    }
    form.setValue(prop, values[prop])
  }
}

/**
 * formの値を一度リセットして入れ直す。
 *
 * サブミット前の挙動に戻す目的で使用。
 * formのValuesを入れ直すため値は変わらないが、resetによりVaridationの挙動を元に戻す。
 */
export function reinsertFormValues(form) {
  const formValues = form.getValues()
  form.reset()
  setFormInit(form, formValues)
}

/**
 * 指定したformのkeysを空文字にセットし直す。
 */
export function setFormInEmpty(form, keys) {
  for (let i = 0; i < keys.length; i++) {
    form.setValue(keys[i], '')
  }
}

export function createDiffParams(e, form, keyNames, diffNames) {
  e.preventDefault()
  const reqestParams = []
  form.forEach((record) => {
    const update = {}
    diffNames.forEach((name) => {
      const diffName = name + '_diff'
      if (record[diffName]) update[name] = record[name]
    })
    if (Object.keys(update).length) {
      keyNames.forEach((keyName) => (update[keyName] = record[keyName]))
      reqestParams.push(update)
    }
  })
  return reqestParams
}

export function downloadBlob(fileName, blobData) {
  const url = URL.createObjectURL(blobData, { type: 'application/octet-stream' })
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.download = fileName
  a.href = url
  a.click()
  a.remove()
  URL.revokeObjectURL(url)
}

export function loadSingleFile(id, func) {
  const elem = document.getElementById(id)
  const file = elem.files[0]
  if (!file) return
  if (file.size > 32000) {
    alert('ファイルサイズが32,000バイトを超えています。')
    return
  }
  const reader = new FileReader()
  reader.onload = (event) => func(reader, event)
  reader.readAsText(file)
  elem.value = ''
}

// import { useState, useRef, useCallback, useEffect } from "react"

// /**
//  * ステートオブジェクトの差分をマージして更新する。
//  * ステートオブジェクトのプロパティは、浅いコピーのマージで更新される。
//  *
//  * ※旧関数のため、新規で利用する場合useStateManage関数を推奨。
//  *
//  * @param {*} object
//  * @return
//  */
// export const useStateMerge = (object) => {
//   const [state, setState] = useState(object)
//   const ref = useRef(object)

//   const _set = (_object) => {
//     const newState = { ...ref.current, ..._object }
//     setState({ ...newState, ..._object })
//     ref.current = newState
//   }
//   return [state, _set]
// }

/**
 * 指定したキーのみを持つオブジェクトを返却する。
 * @param obj
 * @param keys 抽出したいキーの配列
 * @return object
 */
export function extractKeys(obj, keys) {
  return keys.reduce((result, key) => {
    if (key in obj) {
      result[key] = obj[key]
    }
    return result
  }, {})
}

/**
 * 要素型情報
 * @typedef {Object} Element
 * @property {number} no
 * @property {string} value
 */

/**
 * フォームフィールドの配列利用
 *
 * 配列の要素をテキストボックス等のフォームコンポーネントとして利用する目的。
 * react-hook-form の useFieldArray だと、入力フォームのフォーカスが、
 * onChangeなどで外れてしまうため作成。
 * @param {UseFormReturn} form
 * @param {string} fieldName
 */
export function useFieldArray(form, fieldName) {
  const [state, setState] = useState(false)
  const _name = fieldName
  const method = {
    /** @param {Element} elem */
    append(elem) {
      form.getValues(_name).splice(elem.no, 0, { no: elem.no, value: elem.value })
      form.getValues(_name).forEach((_elem, i) => (_elem.no = i + 1))
      form.setValue(_name, form.getValues(_name)) // 必要！
      form.trigger(_name)
    },

    /** @param {Element} elem */
    remove(elem) {
      form.getValues(_name).splice(elem.no - 1, 1)
      form.getValues(_name).forEach((_elem, i) => (_elem.no = i + 1))
      form.setValue(_name, form.getValues(_name))
      form.trigger(_name)
    },

    /**
     * @param {Element} elem
     * @param {value} object
     */
    update(elem, value) {
      const array = form.getValues(_name)
      // const formElem = array.find((_elem) => _elem.no === elem.no)
      const formElem = array[elem.no - 1]
      formElem.value = value
      form.setValue(_name, array)
    },

    render: () => setState(!state)
  }
  return method
}
