import {
  Card,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  FolderRounded as FolderIcon2
} from '@mui/icons-material'
import { useEffect, useState, memo, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { File as FileIcon, FileMinus as FileMinusIcon } from 'react-feather'
import { FileDragArea, StyledCell, SortCell, Loading } from 'src/components'
import { setLoading } from 'src/components/show/Loading'
import { useApi, downloadBlob, useStateManage } from 'src/util/'
import FileUpload from './FileUpload'
import Bookmark from './Bookmark'
import MakeNewDir from './MakeNewDir'
import RemoteDisplayMore from './RemoteDisplayMore'

const TR = styled('tr')`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

const TD_Small = styled('td')`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 6px 8px;
  :first-of-type {
    padding-left: 16px;
  }
  :last-child {
    padding-right: 16px;
  }
`

const TD_PointerHover = styled(TD_Small)`
  cursor: pointer;
  :hover {
    background-color: #e8f0fe;
  }
`

const TD_MoreHorizIcon = styled(MoreHorizIcon)`
  height: 32px;
  width: 32px;
  padding: 4px;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

const TD = {
  Small: TD_Small,
  HoverColor: TD_PointerHover,
  MoreHorizIcon: TD_MoreHorizIcon
}

const DispTableBody = memo(({ dispState, handlePathClick, handleMoreClick, ...props }) => {
  console.log('DispTableBody')

  return (
    <TableBody {...props}>
      {dispState.dirList.map((row, i) => (
        <TR key={dispState.dirPath + row.fileName}>
          <TD.HoverColor onClick={() => handlePathClick(dispState.dirPath + row.fileName, row.fileType)}>
            {row.fileType === 'd' ? (
              // ? <FolderIcon style={{ height: '20px', width: '20px', color: 'rgba(0, 0, 0, 0.54)', verticalAlign: 'middle' }} />
              <FolderIcon2
                style={{ height: '22px', width: '22px', color: 'rgba(30, 41, 59, 0.87)', verticalAlign: 'middle' }}
              />
            ) : row.fileType === 'l' ? (
              <FileMinusIcon
                style={{ height: '20px', width: '22px', color: 'rgba(0, 0, 0, 0.54)', verticalAlign: 'middle' }}
              />
            ) : (
              <FileIcon
                style={{ height: '20px', width: '22px', color: 'rgba(0, 0, 0, 0.54)', verticalAlign: 'middle' }}
              />
            )}
            <span style={{ marginLeft: '8px' }}>{row.fileName}</span>
          </TD.HoverColor>
          <TD.Small style={{ wordBreak: 'break-all' }}>{row.kbSize}</TD.Small>
          <TD.Small style={{ wordBreak: 'keep-all' }}>{row.timeStamp}</TD.Small>
          <TD.Small style={{ wordBreak: 'keep-all' }}>{row.permission}</TD.Small>
          <TD.Small style={{ wordBreak: 'keep-all' }}>{row.owner}</TD.Small>
          <TD.Small style={{ padding: '0 8px 0 0' }}>
            <TD.MoreHorizIcon onClick={(e) => handleMoreClick(e, row)} />
          </TD.Small>
        </TR>
      ))}
    </TableBody>
  )
})

DispTableBody.propTypes = {
  dispState: PropTypes.object,
  handlePathClick: PropTypes.func,
  handleMoreClick: PropTypes.func
}

const GridTemplate = styled(Box)(({ theme, xs, sm, md, lg, xl }) => ({
  display: 'grid',
  // gridTemplateColumns: template,
  // gridGap: '16px',
  // margin: 'auto',
  // gridAutoRows: '100px',
  [theme.breakpoints.up('xs')]: xs && {
    gridTemplateColumns: xs
  },
  [theme.breakpoints.up('sm')]: sm && {
    gridTemplateColumns: sm
  },
  [theme.breakpoints.up('md')]: md && {
    gridTemplateColumns: md
  },
  [theme.breakpoints.up('lg')]: lg && {
    gridTemplateColumns: lg
  },
  [theme.breakpoints.up('xl')]: xl && {
    gridTemplateColumns: xl
  }
}))

const RemoteDisplay = ({ conn }) => {
  console.info(`remote_id: ${conn.selectedKey}`)
  const request = useApi({ clearMessage: true })
  const [dispState, setDispState] = useState({
    remote_id: conn.selectedKey,
    dirPath: '',
    navPath: [],
    total: 0,
    dirList: []
  })
  const [sort, setSort] = useState({ name: '', desc: false })
  const { state, setObject } = useStateManage({ open: false, anchorEl: null, callArg: {} })

  const handleMoreClick = useCallback(
    (e, row) => {
      setObject({ open: true, anchorEl: e.currentTarget, callArg: row })
    },
    [dispState.dirList]
  )

  const handleFileDownload = async (absolutePath) => {
    await request.get('/rmt/cmdGetFile', { remote_id: conn.selectedKey, absolutePath }, ({ body }) => {
      if (body.size) {
        downloadBlob(absolutePath.substring(absolutePath.lastIndexOf('/') + 1), body)
      }
    })
  }

  const handlePathClick = async (absolutePath, fileType) => {
    const startTime = performance.now()
    await setLoading('load1', true)
    if (fileType === undefined || fileType === 'd' || fileType === 'l') {
      await request.get(
        '/rmt/cmdLs',
        { remote_id: conn.selectedKey, absolutePath },
        ({ body }) => {
          const _nav = []
          const paths = body.dirPath.slice(0, -1).split('/')
          for (let aPath = '', i = 0; i < paths.length; i++) {
            aPath += paths[i] + '/'
            _nav.push({ pathName: paths[i], absolutePath: aPath })
          }
          setDispState({
            remote_id: conn.selectedKey,
            dirPath: body.dirPath,
            navPath: _nav,
            total: body.ls.length,
            dirList: body.ls
          })
          setSort({ name: '', desc: false })
        },
        ({ body }) => {
          if (body.type === 'SshError') {
            setDispState({ ...dispState, ...{ dirList: [], navPath: [], total: 0 } })
          }
        }
      )
    } else {
      await handleFileDownload(absolutePath)
    }
    await setLoading('load1', false)
    console.log(performance.now() - startTime)
  }

  useEffect(() => {
    handlePathClick()
  }, [conn.selectedKey])

  // useAsyncCallback
  const handleSort = async (_sort) => {
    if (!_sort) return
    await setLoading('load1', true, 100)
    const dirList = [...dispState.dirList]
    if (_sort.desc) {
      dirList.sort((a, b) => {
        return a[_sort.name] > b[_sort.name] ? -1 : a[_sort.name] < b[_sort.name] ? 1 : 0
      })
    } else {
      dirList.sort((a, b) => {
        return a[_sort.name] < b[_sort.name] ? -1 : a[_sort.name] > b[_sort.name] ? 1 : 0
      })
    }
    setSort(_sort)
    setDispState({ ...dispState, ...{ dirList } })
    setLoading('load1', false)
  }

  return (
    <Card sx={{ position: 'relative' }}>
      <Loading name="load1" sx={{ top: '10px' }} />
      <RemoteDisplayMore
        dispState={dispState}
        moreMenu={state}
        setMoreMenu={setObject}
        handlePathClick={handlePathClick}
      />
      <GridTemplate px={1} py={0.5} xs="1fr" sm="1fr auto">
        <Box sx={{ order: { xs: 3, sm: 1 }, minHeight: '34px', display: 'flex', flexWrap: 'wrap' }}>
          {dispState.navPath.map((item, i = 0) => (
            <Button
              key={item.absolutePath}
              sx={{ minWidth: 0, p: 0.25, textTransform: 'none', '&:hover': { backgroundColor: '#e8f0fe' } }}
              onClick={() => handlePathClick(item.absolutePath)}
            >
              {item.pathName}
              <span style={{ paddingLeft: i === 0 && item.pathName === '' ? 18 : 4 }}>/</span>
            </Button>
          ))}
        </Box>
        <Stack
          sx={{ order: 2, display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' }, alignItems: 'center' }}
          direction="row"
          divider={<Divider orientation="vertical" flexItem variant="middle" />}
        >
          <IconButton
            size="small"
            sx={{ ml: 1, mr: 1 }}
            onClick={() => {
              conn.hide = !conn.hide
              conn.drow()
            }}
          >
            {conn.hide ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
          <Bookmark dispState={dispState} handlePathClick={handlePathClick} />
          <FileUpload dispState={dispState} handlePathClick={handlePathClick} />
          <MakeNewDir dispState={dispState} handlePathClick={handlePathClick} />
          <Box sx={{ pl: 1, fontSize: '0.875rem' }}>{dispState.total} 個の項目</Box>
        </Stack>
      </GridTemplate>
      <TableContainer sx={{ position: 'relative' }}>
        <FileDragArea
          fileId="file-selects"
          areaId="file-drag-area-1"
          sx={{ display: 'contents' }}
          callback={() => document.getElementById('file-selects-handle').click()}
        >
          <Table sx={{ borderCollapse: 'separate' }} size="small">
            <TableHead sx={{ wordBreak: 'keep-all' }}>
              <TableRow>
                <SortCell sx={{ minWidth: 180 }} name="fileName" sort={sort} onClick={handleSort}>
                  名前
                </SortCell>
                <SortCell sx={{ minWidth: 120 }} name="size" sort={sort} firstSortDescending onClick={handleSort}>
                  サイズ
                </SortCell>
                <SortCell
                  sx={{ minWidth: 110, width: 180 }}
                  name="timeStamp"
                  sort={sort}
                  firstSortDescending
                  onClick={handleSort}
                >
                  更新日時
                </SortCell>
                <SortCell sx={{ minWidth: 110, width: 110 }} name="permission" sort={sort} onClick={handleSort}>
                  パーミッション
                </SortCell>
                <SortCell name="owner" sort={sort} onClick={handleSort}>
                  所有者
                </SortCell>
                <StyledCell sx={{ width: 24 }} />
              </TableRow>
            </TableHead>
            <DispTableBody
              id="file-drag-area-1"
              dispState={dispState}
              handlePathClick={handlePathClick}
              handleMoreClick={handleMoreClick}
            />
          </Table>
        </FileDragArea>
      </TableContainer>
    </Card>
  )
}

RemoteDisplay.propTypes = {
  conn: PropTypes.object
}

export default RemoteDisplay
