import { useState, memo } from 'react'
import { Box, Button, IconButton, Menu, MenuItem, Divider } from '@mui/material'
import {
  MoreHoriz as MoreHorizIcon,
  MoreVert as MoreVertIcon,
  AddCircleOutline as AddCircleOutlineIcon
} from '@mui/icons-material'
import PropTypes from 'prop-types'

export const MoreIconButton = memo(({ itemList, callArg, icon, sx, paperStyle, children }) => {
  console.log('MoreIconButton')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton
        id="long-button"
        sx={sx}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        {!children ? icon === 'horiz' ? <MoreHorizIcon /> : <MoreVertIcon /> : children}
      </IconButton>
      {itemList && (
        <Menu
          id="long-menu"
          MenuListProps={{ 'aria-labelledby': 'long-button' }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          slotProps={{
            paper: {
              style: {
                ...{
                  maxHeight: 150,
                  minWidth: '20ch'
                },
                ...paperStyle
              }
            }
          }}
        >
          {itemList.map((item) =>
            item.divider ? (
              <Divider key={item.itemName} />
            ) : (
              <MenuItem
                key={item.itemName}
                onClick={() => {
                  setAnchorEl(null)
                  item.call(callArg)
                }}
                disabled={item.disabled && item.disabled(callArg)}
              >
                {item.itemName}
                {item.body && item.body(callArg)}
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </>
  )
})

MoreIconButton.propTypes = {
  itemList: PropTypes.array,
  callArg: PropTypes.any,
  icon: PropTypes.string,
  sx: PropTypes.object,
  paperStyle: PropTypes.object,
  children: PropTypes.any
}

export const MoreIconAddButton = ({ itemList, callArg }) => {
  return (
    <Box component="span" sx={{ position: 'relative' }}>
      <MoreIconButton itemList={itemList} callArg={callArg} sx={{ position: 'absolute', top: '2px', left: '4px' }}>
        <AddCircleOutlineIcon />
      </MoreIconButton>
    </Box>
  )
}

MoreIconAddButton.propTypes = {
  itemList: PropTypes.array,
  callArg: PropTypes.any
}

export const MoreTextButton = memo(({ itemList, callArg, sx, paperStyle, children, ...props }) => {
  console.log('MoreTextButton')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <Button
        id="text-button"
        sx={sx}
        aria-controls={open ? 'text-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
        {...props}
      >
        {children}
      </Button>
      {itemList && (
        <Menu
          id="text-menu"
          MenuListProps={{ 'aria-labelledby': 'text-button' }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          slotProps={{
            paper: {
              style: {
                ...{
                  maxHeight: 150,
                  minWidth: '20ch'
                },
                ...paperStyle
              }
            }
          }}
        >
          {itemList.map((item) =>
            item.divider ? (
              <Divider key={item.itemName} />
            ) : (
              <MenuItem
                key={item.itemName}
                onClick={() => {
                  setAnchorEl(null)
                  item.call(callArg)
                }}
                disabled={item.disabled && item.disabled(callArg)}
              >
                {item.itemName}
                {item.body && item.body(callArg)}
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </>
  )
})

MoreTextButton.propTypes = {
  itemList: PropTypes.array,
  callArg: PropTypes.any,
  sx: PropTypes.object,
  paperStyle: PropTypes.object,
  children: PropTypes.any
}
