import React from 'react'
import { Container, Grid } from '@mui/material'
import OrderSearch from 'src/contents/acmeData/order/OrderSearch'
import OrderTable from 'src/contents/acmeData/order/OrderTable'
import { MainProvider, useInit } from 'src/util/'
import { MainContent } from 'src/components'

const AcmeOrder = () => {
  console.log('AcmeOrder')
  useInit().setContract()

  return (
    <MainContent title="AcmeOrder | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OrderSearch />
            </Grid>
            <Grid item xs={12}>
              <OrderTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default AcmeOrder
