import { Button, Card, CardContent, Divider, Grid, FormControl, Input, InputLabel, InputAdornment } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { TextField, Dialog, HeaderBox, FooterBox, StyledTextField, ReadText } from 'src/components'
import { useApi, setFormInit, useMainRef } from 'src/util/'
import PropTypes from 'prop-types'
import AccountInfoMore from './AccountInfoMore'

// /**
//  * @param {Object} props
//  * @param {string} props.label
//  * @param {string} props.value
//  */
// function ReadText({ label, value }) {
//   return (
//     // <StyledTextField
//     //   label={label}
//     //   value={value}
//     //   inputProps={{ readOnly: 1 }}
//     //   InputLabelProps={{ shrink: true }}
//     //   size="small"
//     //   fullWidth
//     //   variant="standard"
//     // />
//     <FormControl fullWidth variant="standard">
//       <Input value={value} startAdornment={<InputAdornment position="start">{label + ':'}</InputAdornment>} />
//     </FormControl>
//   )
// }
// ReadText.propTypes = {
//   label: PropTypes.string,
//   value: PropTypes.string
// }

function displayDate(date, prefixComment, suffixComment) {
  if (!date) return '無し'
  return `${prefixComment} ${date} ${suffixComment}`
}

function displayCidr(cidrs) {
  if (!cidrs) return '無し'
  if (cidrs.toString() === '0.0.0.0/0') return '無し (0.0.0.0/0)'
  if (cidrs.toString() === '::/0') return '無し (::/0)'
  return cidrs.toString()
}

const ACCOUNT = yup.object({
  user_name: yup.string().trim().required('名前は必須項目です。'),
  mail: yup.string().nullable().email('有効なメールアドレス形式でありません。')
})

const AccountInfo = () => {
  console.log('AccountInfo')
  const request = useApi()
  const [render, setRender] = useState(false)
  const ref = useMainRef()

  const form = useForm({
    defaultValues: {
      user_name: '',
      mail: '',
      contract_name: '',
      permission_group_name: '',
      tfa_updated_at: '',
      allowed_ipv4: [],
      allowed_ipv6: [],
      access_expire_at: '',
      refresh_expire_at: '',
      acme_accounts: []
    },
    resolver: yupResolver(ACCOUNT)
  })

  const init = () => {
    request.get('/myInfo', null, ({ body }) => {
      console.log('AccountInfo - init')
      setFormInit(form, body.data)
      ref.set('disabled_tfa_del', !Boolean(form.getValues('tfa_updated_at'))) // 二要素認証の削除 でdisabledに利用
      ref.set('disabled_authz_token_del', !Boolean(form.getValues('refresh_expire_at'))) // 認可トークンセットの無効化 でdisabledに利用
      ref.set('main_form_data', form.getValues())
      setRender(!render)
    })
  }
  useEffect(init, [])

  return (
    // <form autoComplete="off" noValidate style={{ height: '100%' }}>
    <Card sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <HeaderBox title="アカウントの情報">
        <AccountInfoMore />
      </HeaderBox>
      <Divider />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <ReadText label="名前" value={form.getValues('user_name')} copyable />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReadText label="メール" value={form.getValues('mail')} copyable />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReadText label="契約名" value={form.getValues('contract_name')} copyable />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReadText label="権限グループ" value={form.getValues('permission_group_name')} copyable />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReadText label="IPv4アドレス制限" value={displayCidr(form.getValues('allowed_ipv4'))} copyable />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReadText label="IPv6アドレス制限" value={displayCidr(form.getValues('allowed_ipv6'))} copyable />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReadText
              label="認可トークン"
              value={displayDate(form.getValues('access_expire_at'), '有り (', 'まで)')}
              copyable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReadText
              label="リフレッシュトークン"
              value={displayDate(form.getValues('refresh_expire_at'), '有り (', 'まで)')}
              copyable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReadText
              label="二要素認証"
              value={displayDate(form.getValues('tfa_updated_at'), '有り (', '作成)')}
              copyable
            />
          </Grid>
          {form.getValues('acme_accounts').length !== 0 && (
            <Grid item xs={12} md={6}>
              <ReadText label="ACMEアカウント" value={form.getValues('acme_accounts').toString()} copyable />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
    // </form>
  )
}

export default AccountInfo
