import { Box, Button, Collapse, DialogActions, DialogContent, Divider, Grid, Link, Tooltip } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { memo, useEffect } from 'react'
import * as yup from 'yup'
import {
  Dialog,
  DialogText,
  TextField,
  TextPassword,
  ReadText,
  MoreIconButton,
  MoreTextButton,
  RenderingSuppress,
  Snackbar
} from 'src/components'
import { useApi, useStateManage, useMainRef, setFormInit, useGlobal, CALL } from 'src/util/'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IpAddressLimit } from './IpAddressLimit'

function AccountInfoMore() {
  console.log('AccountInfoMore')
  const ref = useMainRef()
  const gRef = useGlobal()
  const request = useApi()
  const { state, setValue } = useStateManage({
    1: { open: false },
    2: { open: false },
    3: { open: false, expanded: false },
    4: { open: false },
    5: { open: false, isMaked: false, access_token: '', refresh_token: '', tooltipOpen: false },
    6: { open: false }
  })

  const itemList = [
    { itemName: 'パスワード変更', call: () => setValue(1, { open: true }) },
    { itemName: 'アカウント情報変更', call: () => setValue(2, { open: true }) },
    { itemName: 'divider1', divider: true },
    { itemName: '二要素認証の登録', call: () => setValue(3, { open: true }) },
    {
      itemName: '二要素認証の削除',
      call: () => setValue(4, { open: true }),
      disabled: () => ref.get('disabled_tfa_del')
    },
    { itemName: 'divider2', divider: true },
    { itemName: '認可トークンセットの発行', call: () => setValue(5, { open: true }) },
    {
      itemName: '認可トークンセットの無効化',
      call: () => {
        request.delete('/authz/myToken', null, () => {
          ref.call('CALL_SNACKBAR', '認可トークンセットを無効化しました。')
          document.getElementById('pageReflesh').click()
        })
      },
      disabled: () => ref.get('disabled_authz_token_del')
    },
    { itemName: 'divider3', divider: true },
    { itemName: 'IPアドレス制限', call: () => setValue(6, { open: true }) }
  ]

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} paperStyle={{ maxHeight: 450 }} />
      <RenderingSuppress show={state[1].open}>
        <PasswordChange state={state[1]} setState={(obj) => setValue(1, obj)} />
      </RenderingSuppress>
      <RenderingSuppress show={state[2].open}>
        <MyAccountEdit state={state[2]} setState={(obj) => setValue(2, obj)} />
      </RenderingSuppress>
      <RenderingSuppress show={state[3].open}>
        <TowFactorAuthnReg state={state[3]} setState={(obj) => setValue(3, obj)} />
      </RenderingSuppress>
      <RenderingSuppress show={state[4].open}>
        <TowFactorAuthnDel state={state[4]} setState={(obj) => setValue(4, obj)} />
      </RenderingSuppress>
      <RenderingSuppress show={state[5].open} reHide>
        <NewAuthzTokenMake state={state[5]} setState={(obj) => setValue(5, obj)} />
      </RenderingSuppress>
      <RenderingSuppress show={state[6].open} reHide>
        <IpAddressLimit state={state[6]} setState={(obj) => setValue(6, obj)} />
      </RenderingSuppress>
    </>
  )
}
export default AccountInfoMore

const PASSWORD_CHANGE = yup.object({
  current_password: yup.string().required('現在のパスワードは必須項目です。'),
  new_password: yup
    .string()
    .required('新しいパスワードは必須項目です。')
    .matches(
      /^(?=.*?[a-zA-Z])(?=.*[0-9])(?=.*[#$%&@*+\-_])[a-zA-Z0-9#$%&@*+\-_]{8,32}$/,
      'パスワードは8～32文字で半角英数字と記号を含めてください。'
    )
    .notOneOf([yup.ref('current_password')], '現在のパスワードと同じパスワードは使用できません。'),
  confirm: yup
    .string()
    .required('新しいパスワードの確認は必須項目です。')
    .oneOf([yup.ref('new_password')], '新しいパスワードと一致しません。')
})

const PasswordChange = ({ state, setState }) => {
  console.log('PasswordChange')
  const request = useApi({ clearMessage: true })

  const form = useForm({
    defaultValues: { current_password: '', new_password: '', confirm: '' },
    resolver: yupResolver(PASSWORD_CHANGE)
  })

  const onSubmit = (values) => {
    request.put('/myPassword', values, ({ body }) => {
      if (body.success) {
        document.getElementById('pageReflesh').click()
      }
    })
  }

  const handleReset = () => {
    setState({ open: false })
    form.reset()
  }

  return (
    <Dialog
      title="パスワードの変更"
      open={state.open}
      close={{ dialog: () => setState({ open: false }), form: form.reset }}
    >
      <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
        <DialogText sx={{ mb: 2 }}>現在のパスワードと新しいパスワードを入力してください。</DialogText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextPassword form={form} name="current_password" label="現在のパスワード*" />
          </Grid>
          <Grid item xs={12}>
            <TextPassword form={form} name="new_password" label="新しいパスワード*" />
          </Grid>
          <Grid item xs={12}>
            <TextPassword form={form} name="confirm" label="新しいパスワードの確認*" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>キャンセル</Button>
        <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PasswordChange.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}

const MYACCOUNT_EDIT = yup.object({
  user_name: yup.string().trim().required('名前は必須項目です。'),
  mail: yup.string().nullable().email('有効なメールアドレス形式でありません。')
})

const MyAccountEdit = ({ state, setState }) => {
  console.log('MyAccountEdit')
  const request = useApi({ clearMessage: true })
  const gRef = useGlobal()

  const form = useForm({
    defaultValues: { user_name: '', mail: '' },
    resolver: yupResolver(MYACCOUNT_EDIT)
  })

  const handleReset = () => {
    setState({ open: false })
    form.reset()
  }

  const onSubmit = (values) => {
    request.put('/myInfo', values, ({ body }) => {
      gRef.call(CALL.DASHBOARD_LAYOUT)
      document.getElementById('pageReflesh').click()
    })
  }

  return (
    <Dialog
      title="アカウント情報の修正"
      open={state.open}
      close={{ dialog: () => setState({ open: false }), form: form.reset }}
    >
      <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
        <DialogText sx={{ mb: 2 }}>修正するアカウント情報を入力してください。</DialogText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField form={form} name="user_name" label="名前" />
          </Grid>
          <Grid item xs={12}>
            <TextField form={form} name="mail" label="メール" inputProps={{ maxLength: 64 }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>キャンセル</Button>
        <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MyAccountEdit.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}

const QR_CODE = yup.object({
  secret_key: yup.string().required(),
  authn_code: yup.string().required().max(6)
})

const TowFactorAuthnReg = ({ state, setState }) => {
  console.log('TowFactorAuthnReg')
  const request = useApi()
  // const [expanded, setExpanded] = useState(false)

  const form = useForm({
    defaultValues: { qr_code: '', account_name: '', secret_key: '', authn_code: '' },
    resolver: yupResolver(QR_CODE)
  })

  const close = {
    // ※ダイアログを閉じた際に、すべて初期化すること
    dialog: () => {
      setState({ open: false, expanded: false })
      form.reset()
    }
  }

  const onSubmit = (values) => {
    request.post('/twoFactor/register', { secret_key: values.secret_key, authn_code: values.authn_code }, () => {
      close.dialog()
      document.getElementById('pageReflesh').click()
    })
  }

  useEffect(() => {
    if (!state.open) return
    request.get('/twoFactor/qrCode', null, ({ body }) => {
      setFormInit(form, body)
      form.trigger('_render')
    })
  }, [state.open])

  return (
    <Dialog title="二要素認証の登録" open={state.open} close={close} maxWidth="md">
      <DialogContent sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', pb: 2 }}>
        <Box sx={{ pb: 2 }}>
          STEP1.スマートフォン等のモバイルデバイスに、二要素認証アプリをインストールしてください。
          <br />
          Google認証（推奨アプリ）：
          <Link
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            target="_blank"
          >
            Android版はこちら
          </Link>
          。
          <Link href="https://apps.apple.com/jp/app/google-authenticator/id388497605" target="_blank">
            iPhone版はこちら
          </Link>
          。
        </Box>

        <Divider />
        <Box sx={{ py: 2 }}>
          STEP2.アプリを起動し、下記QRコードをスキャンしてください。
          <br />
          <img src={form.getValues('qr_code')} alt="QRコード" />
          <br />
          <Link
            href="#"
            onClick={() => {
              setState({ expanded: !state.expanded })
            }}
          >
            QRコードを利用できない場合
          </Link>
          は、
          <Collapse in={state.expanded} timeout="auto" unmountOnExit>
            <Box sx={{ mb: 1 }}>下記のセットアップキーを入力してください。</Box>
            アカウント名：{form.getValues('account_name')}（任意）
            <br />
            キー：{form.getValues('secret_key')}
          </Collapse>
        </Box>

        <Divider />
        <Box sx={{ pt: 2 }}>
          STEP3.アプリに表示された、6桁の確認コードを入力してください。
          <TextField form={form} name="authn_code" label="確認コード*" sx={{ mt: 2 }} inputProps={{ maxLength: 6 }} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid #e0e0e0' }}>
        <Button onClick={close.dialog}>キャンセル</Button>
        <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TowFactorAuthnReg.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}

const TowFactorAuthnDel = ({ state, setState }) => {
  console.log('TowFactorAuthnDel')
  const request = useApi()

  const handleDelete = () => {
    request.delete('/twoFactor/register', null, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  return (
    <Dialog title="二要素認証の削除" open={state.open} close={() => setState({ open: false })} maxWidth="md">
      <DialogContent>
        ログイン時の二要素認証コードを入力する必要がなくなります。
        <br />
        二要素認証アプリのアカウント登録情報については、アプリから手動で削除する必要があります。
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid #e0e0e0' }}>
        <Button onClick={() => setState({ open: false })}>キャンセル</Button>
        <Button type="submit" onClick={handleDelete}>
          削除
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TowFactorAuthnDel.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}

const NewAuthzTokenMake = ({ state, setState }) => {
  console.log('NewAuthzTokenMake')
  const request = useApi()

  const handleClose = () => {
    if (state.isMaked) {
      document.getElementById('pageReflesh').click()
    } else {
      setState({ open: false, isMaked: false, access_token: '', refresh_token: '' })
    }
  }

  const handleMake = () => {
    request.post('/authz/myToken/new', null, ({ body }) => {
      setState({ isMaked: true, access_token: body.access_token, refresh_token: body.refresh_token })
    })
  }

  const handleCmd = (resp_prop) => {
    request.get(
      '/authz/myToken/cmd',
      { api_req_method: 'get', api_req_path: '/api/myInfo', resp_prop },
      async ({ body }) => {
        document.getElementById('MoreTextButton').focus()
        const text = body[resp_prop]
        await navigator.clipboard?.writeText(text).then()
        setState({ tooltipOpen: true })
        setTimeout(() => setState({ tooltipOpen: false }), 1000)
      }
    )
  }

  const itemList = [
    { itemName: 'CURL（各Linuxサーバ）', call: () => handleCmd('curl') },
    { itemName: 'FETCH（同一ブラウザタブ、NodeJS）', call: () => handleCmd('fetch') },
    { itemName: 'Invoke-WebRequest（PowerShell）', call: () => handleCmd('power_shell') }
  ]

  return (
    <Dialog
      title="認可トークンセットの発行"
      open={state.open}
      backdropCloseOff
      close={handleClose}
      maxWidth={state.isMaked ? 'lg' : 'md'}
    >
      <DialogContent>
        ※新規トークンを発行すると古いトークンは全て無効になります。発行後は新しいトークンに入れ替えてください。
        <ReadText
          label="アクセストークン"
          value={state.access_token}
          sx={{ mt: 3 }}
          copyable
          disabled={!state.isMaked}
          inputSx={{ fontFamily: 'mono', fontSize: 13 }}
          inputProps={{ multiline: true }}
        />
        <ReadText
          label="リフレッシュトークン"
          value={state.refresh_token}
          sx={{ mt: 3 }}
          copyable
          disabled={!state.isMaked}
          inputSx={{ fontFamily: 'mono', fontSize: 13 }}
          inputProps={{ multiline: true }}
        />
      </DialogContent>
      {state.isMaked ? (
        <DialogActions sx={{ borderTop: '1px solid #e0e0e0' }}>
          <MoreTextButton
            id="MoreTextButton"
            // variant="contained"
            itemList={itemList}
            endIcon={
              <Tooltip title="Copied!" open={state.tooltipOpen} disableHoverListener placement="top">
                <ContentCopyIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              </Tooltip>
            }
          >
            コマンドサンプル
          </MoreTextButton>
          <Button onClick={handleClose}>閉じる</Button>
        </DialogActions>
      ) : (
        <DialogActions sx={{ borderTop: '1px solid #e0e0e0' }}>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="submit" onClick={handleMake}>
            発行
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

NewAuthzTokenMake.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}
