import { TextField as TextFieldMui, TableBody, TableRow, DialogContent, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { Dialog, StyledCell, StyledLinkCell, MoreIconButton } from 'src/components'
import { useApi, useStateManage } from 'src/util/'

const SciWsTableBody = ({ list }) => {
  const request = useApi()
  // const [open, setState] = useState(false)
  const { state, setObject } = useStateManage({ dialog1: false, request_id: '', args: '', result: '' })

  const handleDetail = (callArg) => {
    const args = JSON.stringify(JSON.parse(callArg.args), null, '  ')
    const result = JSON.stringify(JSON.parse(callArg.sci_result), null, '  ')
    setObject({ dialog1: true, request_id: callArg.request_id, args, result })
    // request.delete('/acme/account', { account_id: callArg.account_id }, () => {
    //   document.getElementById('pageReflesh').click()
    // })
  }

  const handleAcmeCli = (callArg) => {
    // request.get('/acme/certbotCli', { account_id: callArg.account_id }, async ({ body }) => {
    //   document.getElementById('MoreIconButton').focus()
    //   await navigator.clipboard?.writeText(body.certbot_command).then()
    //   setState(true)
    // })
  }

  const itemList = [{ itemName: 'リクエスト詳細', call: (callArg) => handleDetail(callArg) }]

  return (
    <TableBody>
      {list.map((row, index) => (
        <TableRow hover key={row.request_id}>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.request_id}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.sciws_name}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.method}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.args_service_info_id}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.respons_status_cd}</StyledCell>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.respons_at}</StyledCell>
          <StyledCell id="MoreIconButton" align="center" sx={{ p: '0', height: '42px' }}>
            <MoreIconButton itemList={itemList} callArg={row} icon="horiz" sx={{ m: '-8px' }} />
          </StyledCell>
        </TableRow>
      ))}

      <Dialog
        title={`SCI-WSへのリクエスト履歴（ID=${state.request_id}）`}
        open={state.dialog1}
        close={() => setObject({ dialog1: false })}
        maxWidth="lg"
      >
        <DialogContent sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldMui
                name="sciws_args"
                label="リクエスト（JSON）"
                value={state.args}
                multiline
                fullWidth
                rows={15}
                inputProps={{ style: { fontSize: 'small' }, maxLength: 10000, readOnly: 1 }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldMui
                name="response"
                label="レスポンス結果（抜粋）"
                value={state.result}
                multiline
                fullWidth
                rows={15}
                inputProps={{ style: { fontSize: 'small' }, maxLength: 10000, readOnly: 1 }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </TableBody>
  )
}

SciWsTableBody.propTypes = {
  list: PropTypes.array.isRequired
}

export default SciWsTableBody
