import { Box, Divider, Table, TableHead, TableBody, TableRow, TableContainer } from '@mui/material'
import PropTypes from 'prop-types'
import { StyledCell, Dialog, RenderingSuppress, MoreIconButton } from 'src/components'
import { useApi, useStateManage } from 'src/util/'

const OrderTableBody = ({ ejbca_id, list }) => {
  const { state, setObject } = useStateManage({
    d2: false,
    d2Title: '',
    d2List: []
  })

  const itemList = [
    {
      itemName: 'チャレンジ詳細',
      call: (callArg) =>
        setObject({
          d2: true,
          d2Title: `オーダー No.${callArg.order_id} チャレンジ`,
          d2List: callArg.authorizations
        })
    }
  ]

  return (
    <TableBody>
      {list.map((row, index) => {
        const auth = row.authorizations
        return (
          <TableRow hover key={`${row.order_id}_${auth[0] && auth[0].authorization_id + '_' + auth[0].challenge_id}`}>
            <StyledCell>{row.order_id}</StyledCell>
            <StyledCell>{row.contract_id}</StyledCell>
            <StyledCell sx={{ wordBreak: 'break-all' }}>{row.account_id}</StyledCell>
            <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.expires}</StyledCell>
            <StyledCell sx={{ wordBreak: 'keep-all' }}>
              {row.status}
              {row.status === 'invalid' && '（破棄）'}
              {row.status === 'pending' && '（保留中）'}
              {row.status === 'ready' && '（準備済）'}
              {row.status === 'processing' && '（発行中）'}
              {row.status === 'valid' && '（発行済）'}
            </StyledCell>
            <StyledCell sx={{ wordBreak: 'break-all' }}>{row.issued_subject_dn}</StyledCell>
            <StyledCell sx={{ wordBreak: 'break-all' }}>{row.download_count}</StyledCell>
            <StyledCell id="MoreIconButton" align="center" sx={{ p: '0', height: '42px' }}>
              <MoreIconButton itemList={itemList} callArg={row} icon="horiz" sx={{ m: '-8px' }} />
            </StyledCell>
          </TableRow>
        )
      })}
      <RenderingSuppress show={state.d2}>
        <ChallTable
          open={state.d2}
          onClose={() => setObject({ d2: false })}
          title={state.d2Title}
          list={state.d2List}
        />
      </RenderingSuppress>
    </TableBody>
  )
}

OrderTableBody.propTypes = {
  ejbca_id: PropTypes.number,
  list: PropTypes.array.isRequired
}

export default OrderTableBody

export const ChallTable = ({ title, open, onClose, list }) => {
  console.log('ChallTable')

  const viewErrorMsg = (message) => {
    if (!message) return ''
    try {
      return JSON.stringify(JSON.parse(message), null, '  ')
    } catch (e) {
      return message
    }
  }

  return (
    <Dialog title={title} open={open} close={onClose} maxWidth="xl">
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <StyledCell sx={{ minWidth: 70 }} name="authz_status">
                認可状態
              </StyledCell>
              <StyledCell sx={{ minWidth: 90 }} name="authz_expires">
                認可期限
              </StyledCell>
              <StyledCell sx={{ minWidth: 160 }} name="identifier_value">
                ドメイン（識別値）
              </StyledCell>
              <StyledCell sx={{ minWidth: 90 }} name="validated">
                検証時刻
              </StyledCell>
              <StyledCell sx={{ minWidth: 70 }} name="chall_type">
                タイプ
              </StyledCell>
              <StyledCell sx={{ minWidth: 90 }} name="t3.status">
                ステータス
              </StyledCell>
              <StyledCell sx={{ minWidth: 160 }} name="error">
                検証エラー
              </StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <>
                <TableRow hover key={row.authorization_id}>
                  <StyledCell sx={{ wordBreak: 'break-all', height: '42px' }}>{row.authz_status}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.authz_expires?.slice(0, 10)}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.identifier_value}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.validated}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.chall_type}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.chall_status}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>
                    <pre>{viewErrorMsg(row.error)}</pre>
                  </StyledCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  )
}

ChallTable.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  list: PropTypes.array.isRequired
}
