import { Box, Button, Collapse, DialogActions, DialogContent, Divider, Grid, Link } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { memo, useEffect, useState, useRef } from 'react'
import * as yup from 'yup'
import {
  Dialog,
  DialogText,
  TextField,
  TextPassword,
  ReadText,
  MoreIconButton,
  MoreIconAddButton,
  RenderingSuppress,
  Snackbar
} from 'src/components'
import { useApi, useStateManage, useMainRef, setFormInit, useFieldArray } from 'src/util/'
import IPCIDR from 'ip-cidr'

const IPV4_REGEX =
  /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\/([0-9]|[1-2][0-9]|3[0-2]))$/

const IPV6_REGEX =
  /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]|[1-9]?)?[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]|[1-9]?)?[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]|[1-9]?)?[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]|[1-9]?)?[0-9]))\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8])$/

const IPADDRESS_LIMIT = yup.object({
  ipv4_cidrs: yup.array().of(
    yup.object({
      value: yup
        .string()
        .required('CIDRは必須項目です。')
        .test('', 'IPv4アドレス範囲をCIDR形式（例: 192.168.0.100/32）で入力してください。', (value) =>
          value?.match(IPV4_REGEX)
        )
    })
  ),
  ipv6_cidrs: yup.array().of(
    yup.object({
      value: yup
        .string()
        .required('CIDRは必須項目です。')
        .test('', 'IPv6アドレス範囲をCIDR形式（例: fd00::c0a8:64/128）で入力してください。', (value) =>
          value?.match(IPV6_REGEX)
        )
    })
  )
})

function toCidrRangeString(form, cidr) {
  if (!cidr) return ''
  try {
    const _cidr = new IPCIDR(cidr)
    return `範囲（${_cidr.start()} - ${_cidr.end()}）`
  } catch {
    return ''
  }
}

export const IpAddressLimit = ({ state, setState }) => {
  console.log('IpAdressLimit')
  const request = useApi()
  const ref = useMainRef()

  const form = useForm({
    defaultValues: {
      ipv4_cidrs: [], // { no: 1, value: '' }
      ipv6_cidrs: [] // { no: 1, value: '' }
    },
    resolver: yupResolver(IPADDRESS_LIMIT)
  })
  const fav4 = useFieldArray(form, 'ipv4_cidrs')
  const fav6 = useFieldArray(form, 'ipv6_cidrs')

  const onSubmit = async (values) => {
    const req_ipv4_cidrs = []
    values.ipv4_cidrs.forEach((elem) => {
      if (elem.value !== '') req_ipv4_cidrs.push(elem.value)
    })
    const req_ipv6_cidrs = []
    values.ipv6_cidrs.forEach((elem) => {
      if (elem.value !== '') req_ipv6_cidrs.push(elem.value)
    })
    await request.post('/myIp/allow', { ipv4_cidrs: req_ipv4_cidrs, ipv6_cidrs: req_ipv6_cidrs }, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  useEffect(() => {
    if (!state.open) return
    const mainForm = ref.get('main_form_data')
    for (let i = 0; i < mainForm.allowed_ipv4.length; i++) {
      fav4.append({ no: i + 1, value: mainForm.allowed_ipv4[i] })
    }
    for (let i = 0; i < mainForm.allowed_ipv6.length; i++) {
      fav6.append({ no: i + 1, value: mainForm.allowed_ipv6[i] })
    }
    fav6.render()
  }, [state.open])

  const itemListV4 = [
    {
      itemName: '追加',
      call: (elem) => fav4.append({ no: elem.no, value: '' }),
      disabled: () => form.getValues('ipv4_cidrs').length >= 4
    },
    {
      itemName: '削除',
      call: (elem) => fav4.remove(elem),
      disabled: (elem) => form.getValues('ipv4_cidrs').length === 1 && elem.no === 1
    }
  ]

  const itemListV6 = [
    {
      itemName: '追加',
      call: (elem) => fav6.append({ no: elem.no, value: '' }),
      disabled: () => form.getValues('ipv6_cidrs').length >= 4
    },
    {
      itemName: '削除',
      call: (elem) => fav6.remove(elem),
      disabled: (elem) => form.getValues('ipv6_cidrs').length === 1 && elem.no === 1
    }
  ]

  const close = {
    dialog: () => setState({ open: false }),
    form: () => {
      form.reset()
      setFormInit(form) // _arrayの参照を消す
    }
  }

  return (
    <Dialog title="IPアドレスの制限" open={state.open} close={close} maxWidth="md">
      <DialogContent>
        ※IPv4とIPv6アドレスの制限範囲をCIDR形式で入力してください。範囲外のIPアドレスを遮断します。
        <Grid container rowSpacing={2} columnSpacing={6} sx={{ pr: 4, my: 1 }}>
          {form.getValues('ipv4_cidrs').map((elem, i) => (
            <Grid key={elem.no} item xs={12}>
              <TextField
                fullWidth
                error={Boolean(form.formState.errors.ipv4_cidrs?.[i])}
                helperText={
                  form.formState.errors.ipv4_cidrs?.[i]?.value?.message || toCidrRangeString(form, elem.value)
                }
                label={`V4-CIDR ${elem.no}`}
                form={form}
                name={`ipv4_cidrs.${i}.value`}
                onChange={(e) => {
                  fav4.update(elem, e.target.value)
                  if (IPV4_REGEX.test(e.target.value)) {
                    console.log('!')
                  }
                  form.trigger('ipv4_cidrs')
                }}
              />
              <MoreIconAddButton itemList={itemListV4} callArg={elem} />
            </Grid>
          ))}
        </Grid>
        <Divider />
        <Grid container rowSpacing={2} columnSpacing={6} sx={{ pr: 4, my: 1 }}>
          {form.getValues('ipv6_cidrs').map((elem, i = 0) => (
            <Grid key={elem.no} item xs={12}>
              <TextField
                fullWidth
                error={Boolean(form.formState.errors.ipv6_cidrs?.[i])}
                helperText={
                  form.formState.errors.ipv6_cidrs?.[i]?.value?.message || toCidrRangeString(form, elem.value)
                }
                label={`V6-CIDR ${elem.no}`}
                form={form}
                name={`ipv6_cidrs.${i}.value`}
                onChange={(e) => {
                  fav6.update(elem, e.target.value)
                  form.trigger('ipv6_cidrs')
                }}
              />
              <MoreIconAddButton itemList={itemListV6} callArg={elem} />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid #e0e0e0' }}>
        <Button
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

IpAddressLimit.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}
