import { Box, Button, Container, Divider, Link, Typography } from '@mui/material'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { TextField, TextPassword, SlideContainer, SlideBox } from 'src/components'
import { Alert } from 'src/components/show/Alert'
import { useApi, useStateManage, reinsertFormValues, useGlobal, useContract, CALL } from 'src/util/'
import { useMenuRout } from 'src/Rout'

const LOGIN = yup.object({
  user_id: yup.string().required('User ID は必須項目です。'),
  password: yup.string().required('Password は必須項目です。'),
  new_password: yup.string().when('step', {
    is: 2,
    then: () =>
      yup
        .string()
        .required('新しいパスワードは必須項目です。')
        .matches(
          /^(?=.*?[a-zA-Z])(?=.*[0-9])(?=.*[#$%&@*+\-_])[a-zA-Z0-9#$%&@*+\-_]{8,32}$/,
          'パスワードは8～32文字で半角英数字と記号を含めてください。'
        )
        .notOneOf([yup.ref('password')], '現在のパスワードと同じパスワードは使用できません。')
  }),
  confirm: yup.string().when('step', {
    is: 2,
    then: () =>
      yup
        .string()
        .required('新しいパスワードの確認は必須項目です。')
        .oneOf([yup.ref('new_password')], '新しいパスワードと一致しません。')
  }),
  authn_code: yup.string().when('step', {
    is: 3,
    then: () => yup.string().required('Authcode は必須項目です。')
  })
})

const Login = () => {
  console.log('Login')
  const request = useApi({ clearMessage: true })
  const navigate = useNavigate()
  const gRef = useGlobal(CALL.LOGIN)
  const contract = useContract()

  const { state, setObject } = useStateManage({
    loginNg: false,
    message: '',
    isTwoFactor: false,
    back: false,
    activeStep: 1
  })
  const initializePermission = useMenuRout()

  const form = useForm({
    // mode: 'onBlur',
    defaultValues: { step: 1, user_id: '', password: '', new_password: '', confirm: '', authn_code: '' },
    resolver: yupResolver(LOGIN)
  })

  const onSubmit = (values) => {
    let sendDate = { resp_myinfo: true, resp_api_info: true }
    if (state.activeStep === 1) {
      sendDate = { ...sendDate, user_id: values.user_id, password: values.password }
    } else if (state.activeStep === 2) {
      sendDate = { ...sendDate, user_id: values.user_id, password: values.password, new_password: values.new_password }
    } else if (state.activeStep === 3) {
      sendDate = { ...sendDate, user_id: values.user_id, password: values.password, authn_code: values.authn_code }
    }

    request.post(
      '/login',
      sendDate,
      async ({ user, body }) => {
        const { my_info, api_info } = body

        if (!sessionStorage.getItem('version')) {
          sessionStorage.setItem('version', api_info.version)
        }
        if (user.loginStatus === 1) {
          if (sessionStorage.getItem('version') !== api_info.version) {
            sessionStorage.setItem('token', gRef.getUser().token)
            sessionStorage.setItem('version', api_info.version)
            sessionStorage.setItem('reload', 'true')
          }
          contract.clearPermissionGroup()
          initializePermission(
            my_info.data.permission_group_id,
            my_info.menu,
            my_info.allowedMenuPaths,
            my_info.lastLocation
          )
          navigate(my_info.lastLocation, { replace: true })
        }
      },
      ({ body }) => {
        reinsertFormValues(form)
        if (body.errCode === '001') {
          // form.setFocus('new_password')
          setObject({ loginNg: false })
          setObject({ back: false, activeStep: 2 })
        } else if (body.errCode === '002') {
          // form.setFocus('authn_code')
          setObject({ loginNg: false })
          setObject({ back: false, activeStep: 3 })
        } else {
          setObject({ loginNg: true, message: body.message })
        }
      }
    )
  }

  useEffect(() => {
    const step = state.activeStep
    form.setValue('step', step)
    if (step === 2) {
      setTimeout(() => form.setFocus('new_password'), 300)
    } else if (step === 3) {
      setTimeout(() => form.setFocus('authn_code'), 300)
    }
  }, [state.activeStep])

  const handleBack = () => {
    form.setValue('step', 1)
    form.setValue('authn_code', '')
    // form.resetField('authn_code')
    setObject({ back: true, activeStep: 1 })
  }

  return (
    <>
      <Helmet>
        <title>Login | M System</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ flex: 1, alignContent: 'center' }}>
          <Container maxWidth="sm">
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Box sx={{ pb: 1 }}>
                <Divider variant="middle">
                  <Typography align="center" variant="body1">
                    管理システムログイン
                  </Typography>
                </Divider>
              </Box>
              <Box sx={{ pb: 2 }}>
                {gRef.getMessages().map((message, i = 0) => (
                  <Alert
                    key={i}
                    open={message.open}
                    onClose={() => {
                      // render('messages', i, 'open', false)
                      gRef.closeMessage(CALL.LOGIN, i)
                    }}
                    severity={message.severity}
                  >
                    {message.body}
                  </Alert>
                ))}
                <Alert
                  open={state.loginNg}
                  onClose={() => {
                    setObject({ loginNg: false })
                  }}
                  severity="error"
                  sx={{ mb: 0 }}
                >
                  {state.message}
                </Alert>
              </Box>

              <SlideContainer state={state}>
                <SlideBox state={state} no={1}>
                  <TextField
                    form={form}
                    name="user_id"
                    label="User ID"
                    margin="normal"
                    size="middle"
                    autoComplete="on"
                    autoFocus
                  />
                  <TextPassword form={form} name="password" label="Password" margin="normal" size="middle" />
                </SlideBox>
                <SlideBox state={state} no={2}>
                  <Typography color="textSecondary" fontSize="0.875rem" mt={2}>
                    初期パスワードを変更してください。
                  </Typography>
                  <TextPassword
                    form={form}
                    name="new_password"
                    label="新しいパスワード*"
                    margin="normal"
                    size="middle"
                  />
                  <TextPassword
                    form={form}
                    name="confirm"
                    label="新しいパスワードの確認*"
                    margin="normal"
                    size="middle"
                  />
                </SlideBox>
                <SlideBox state={state} no={3}>
                  <Typography color="textSecondary" fontSize="0.875rem" mt={2}>
                    アカウントは二要素認証で保護されています。認証コードを入力してください。
                  </Typography>
                  <TextField
                    form={form}
                    name="authn_code"
                    label="Authcode"
                    margin="normal"
                    size="middle"
                    inputProps={{ maxLength: 6 }}
                    sx={{ mt: 3 }}
                  />
                </SlideBox>
              </SlideContainer>

              <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                {state.activeStep === 1 && (
                  <>
                    <div />
                    <Button color="primary" type="submit" variant="contained">
                      ログイン
                    </Button>
                  </>
                )}
                {state.activeStep === 2 && (
                  <>
                    <div />
                    <Button color="primary" type="submit" variant="contained">
                      パスワード変更
                    </Button>
                  </>
                )}
                {state.activeStep === 3 && (
                  <>
                    <Button type="button" startIcon={<ChevronLeftIcon />} onClick={handleBack}>
                      前へ
                    </Button>
                    <Button color="primary" type="submit" variant="contained">
                      ログイン
                    </Button>
                  </>
                )}
              </Box>
              <Typography color="textSecondary" variant="body1" fontSize="0.875rem">
                お問い合わせは
                <Link href={process.env.REACT_APP_CONTACT_URL} variant="h6" underline="hover">
                  こちら
                </Link>
              </Typography>
            </form>
          </Container>
        </Box>
        <Box component="footer">
          <Divider />
          <Container>
            <Box sx={{ py: 4 }}>
              <Typography align="center" color="textSecondary" variant="body1" fontSize="0.875rem">
                {process.env.REACT_APP_COPY_RIGHT}
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  )
}

export default Login
