import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Dialog as DialogTag,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button,
  Paper,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useGlobal, CALL } from 'src/util'
import { Alert } from './Alert'

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const DefaultDialogContent = (onClose, children) => {
  return (
    <>
      <DialogContent>
        <DialogText>{children}</DialogText>
      </DialogContent>
      <DialogActions sx={{ px: 1.5 }}>
        {/* <Button color="primary" variant="contained" autoFocus onClick={() => setOpen(false)}> */}
        <Button color="primary" autoFocus onClick={onClose}>
          閉じる
        </Button>
      </DialogActions>
    </>
  )
}

export const DialogText = ({ text, children, sx }) => {
  return (
    <DialogContentText sx={{ ...{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }, ...sx }}>
      {text}
      {text && children && <br />}
      {children}
    </DialogContentText>
  )
}

DialogText.propTypes = {
  text: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.any
}

export const Dialog = ({
  title,
  name,
  open = false,
  close = {},
  backdropCloseOff,
  draggableOff,
  children,
  ...props
}) => {
  const dialogName = 'Dialog:' + (name || title)
  console.log(dialogName)
  const gRef = useGlobal(dialogName)

  useEffect(() => {
    const activeDialog = gRef.getActiveDialog()
    if (open) {
      gRef.setActiveDialog(dialogName)
      if (gRef.getMessages().length !== 0) {
        gRef.pushMessages([], true)
        gRef.call(CALL.PAGE_ALERT)
      }
    }
    if (!open && activeDialog === dialogName) {
      gRef.setActiveDialog('')
    }
  }, [open])

  if (typeof close === 'function') {
    close.dialog = close
  }

  let contents = children
  // if (React.isValidElement(children))
  if (_.isString(children)) contents = DefaultDialogContent(close.dialog, children)

  return (
    <DialogTag
      open={open}
      onClose={backdropCloseOff ? undefined : close.dialog}
      PaperComponent={draggableOff ? undefined : PaperComponent}
      aria-labelledby="draggable-dialog-title"
      scroll="body"
      fullWidth
      {...props}
    >
      <DialogTitle
        id="draggable-dialog-title"
        sx={{
          cursor: draggableOff ? 'auto' : 'move',
          fontSize: 16,
          py: 1,
          px: 2,
          minHeight: '48px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {close.dialog && (
          <IconButton
            aria-label="close"
            size="small"
            sx={{ position: 'absolute', right: 8 }}
            onClick={() => {
              close.form && close.form()
              close.dialog()
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {title}
      </DialogTitle>
      <Divider />
      {gRef.getMessages().map((msg, i = 0) => {
        return (
          <Alert key={i} open={msg.open} onClose={() => gRef.closeMessage(dialogName, i)} severity={msg.severity}>
            {msg.body}
          </Alert>
        )
      })}
      {contents}
    </DialogTag>
  )
}

Dialog.propTypes = {
  title: PropTypes.any,
  name: PropTypes.string,
  open: PropTypes.bool,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.func)]),
  backdropCloseOff: PropTypes.bool,
  draggableOff: PropTypes.bool,
  children: PropTypes.any
}
