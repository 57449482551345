import { useRef } from 'react'
import PropTypes from 'prop-types'

/**
 * 子要素の描画抑制
 *
 * propsで子要素（children）の初回描画を制御します。
 * 「show」がtrueになるまで子要素を描画しません。
 *
 * @param {*} { show, children }
 * @return 子要素または空要素
 */
export const RenderingSuppress = ({ show, reHide, children }) => {
  const initialize = useRef(false)
  if (!initialize.current && show) initialize.current = true
  if (!initialize.current || (reHide && !show)) {
    return <></>
  } else {
    return children
  }
}

RenderingSuppress.propTypes = {
  show: PropTypes.bool,
  reHide: PropTypes.bool,
  children: PropTypes.any
}
