import {
  Box,
  Card,
  Grid,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem
} from '@mui/material'
import PropTypes from 'prop-types'
import { memo, useReducer, useEffect } from 'react'
import {
  Dialog,
  DialogText,
  FileDragArea,
  HeaderBox,
  RenderingSuppress,
  MoreIconButton,
  StyledCell,
  LoadButton,
  TextField,
  Loading,
  Checkbox
} from 'src/components'
import { useApi, useGlobal, deleteNoValue, setFormInEmpty, loadSingleFile, useStateManage } from 'src/util/'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

const ACME_SETTING_FORM = yup.object({
  setting_name: yup.string().trim().required('ACME設定名は必須項目です。'),
  ws_access_channel: yup.string().required().oneOf(['ejbca-ws', 'sci-ws']),
  ejbca_id: yup
    .string()
    .when('ws_access_channel', { is: 'ejbca-ws', then: (s) => s.trim().required('EJBCAサーバは必須項目です。') }),
  ca_name: yup
    .string()
    .when('ws_access_channel', { is: 'ejbca-ws', then: (s) => s.trim().required('CA名は必須項目です。') }),
  ee_profile_name: yup
    .string()
    .when('ws_access_channel', { is: 'ejbca-ws', then: (s) => s.trim().required('EEプロファイルは必須項目です。') }),
  cert_profile_name: yup.string().when('ws_access_channel', {
    is: 'ejbca-ws',
    then: (s) => s.trim().required('証明書プロファイルは必須項目です。')
  }),
  sciws_id: yup
    .string()
    .when('ws_access_channel', { is: 'sci-ws', then: (s) => s.required('SCI-WS設定名は必須項目です。') }),
  service_info_id: yup
    .string()
    .when('ws_access_channel', { is: 'sci-ws', then: (s) => s.required('契約IDは必須項目です。') }),
  cert_type_id: yup
    .string()
    .when('ws_access_channel', { is: 'sci-ws', then: (s) => s.required('証明書タイプIDは必須項目です。') })
})

export const AcmeSettingRegister = ({ dialog, setDialog }) => {
  console.log('AcmeSettingRegister')
  const request = useApi()
  const gRef = useGlobal()

  const { state, setObject } = useStateManage({ expanded1: false, expanded2: false })

  const form = useForm({
    defaultValues: {
      setting_name: '',
      ws_access_channel: '',
      ejbca_id: '',
      ca_name: '',
      ee_profile_name: '',
      cert_profile_name: '',
      sciws_id: '',
      contract_id: '',
      service_info_id: '',
      cert_type_id: '',
      ca_cert_chain: '',
      period: 12,
      multi_domain_flg: true,
      twoway_flg: true,
      wildcard_flg: true
    },
    resolver: yupResolver(ACME_SETTING_FORM)
  })

  console.log(form.getValues())

  const onSubmit = async (values) => {
    deleteNoValue(values)
    await request.post('/acme/acmeSetting', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const close = {
    dialog: () => setDialog({ open: false }),
    form: () => {
      form.reset()
      setObject({ expanded1: false, expanded2: false })
    }
  }

  const handleAcmeAccessPoint = (e) => {
    const acmeAccessPoint = e.target.value
    const setting_name = form.getValues('setting_name')
    form.reset()
    if (acmeAccessPoint === 'ejbca-ws') {
      setObject({ expanded1: true, expanded2: false })
    } else if (acmeAccessPoint === 'sci-ws') {
      setObject({ expanded1: false, expanded2: true })
    } else {
      setObject({ expanded1: false, expanded2: false })
    }
    gRef.pushMessages([], true)
    form.setValue('setting_name', setting_name)
    form.setValue('ws_access_channel', acmeAccessPoint)
  }

  return (
    <Dialog title="ACME設定の登録" open={dialog.open} close={close} maxWidth="lg">
      <Loading name="load2" sx={{ top: '-34px' }} />
      <DialogContent sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="setting_name" label="ACME設定名*" autoFocus inputProps={{ maxLength: 250 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField select form={form} name="ws_access_channel" label="ACME接続先*" onChange={handleAcmeAccessPoint}>
              <MenuItem key={0} value="">
                {'　'}
              </MenuItem>
              <MenuItem key={1} value="ejbca-ws">
                EJBCA-WS接続
              </MenuItem>
              <MenuItem key={2} value="sci-ws">
                SCI-WS接続
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>

      <Collapse in={state.expanded1} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <EjbcaSetting form={form} request={request} open={state.expanded1} close={close} />
      </Collapse>

      <Collapse in={state.expanded2} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <SciSetting form={form} request={request} open={state.expanded2} close={close} />
      </Collapse>

      <Divider variant="middle" />
      <DialogActions>
        <Button
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <LoadButton type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </LoadButton>
      </DialogActions>
    </Dialog>
  )
}

AcmeSettingRegister.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func
}

const ACME_EDIT_FORM = yup.object({
  ws_access_channel: yup.string().required().oneOf(['ejbca-ws', 'sci-ws']),
  ejbca_id: yup
    .string()
    .when('ws_access_channel', { is: 'ejbca-ws', then: (s) => s.trim().required('EJBCAサーバは必須項目です。') }),
  ca_name: yup
    .string()
    .when('ws_access_channel', { is: 'ejbca-ws', then: (s) => s.trim().required('CA名は必須項目です。') }),
  ee_profile_name: yup
    .string()
    .when('ws_access_channel', { is: 'ejbca-ws', then: (s) => s.trim().required('EEプロファイルは必須項目です。') }),
  cert_profile_name: yup.string().when('ws_access_channel', {
    is: 'ejbca-ws',
    then: (s) => s.trim().required('証明書プロファイルは必須項目です。')
  }),
  sciws_id: yup
    .string()
    .when('ws_access_channel', { is: 'sci-ws', then: (s) => s.required('SCI-WS設定名は必須項目です。') }),
  service_info_id: yup
    .string()
    .when('ws_access_channel', { is: 'sci-ws', then: (s) => s.required('契約IDは必須項目です。') }),
  cert_type_id: yup
    .string()
    .when('ws_access_channel', { is: 'sci-ws', then: (s) => s.required('証明書タイプIDは必須項目です。') })
})

export const AcmeSettingEdit = ({ dialog, setDialog }) => {
  console.log('AcmeSettingEdit')
  const request = useApi()
  const gRef = useGlobal()
  const { state, setObject } = useStateManage({
    expanded1: false,
    expanded2: false,
    acmeSettings: [],
    acmeSetting: {},
    pulldownChangeFlg: false
  })

  const form = useForm({
    defaultValues: {
      setting_id: '',
      ws_access_channel: '',
      ejbca_id: '',
      ca_name: '',
      ee_profile_name: '',
      cert_profile_name: '',
      sciws_id: '',
      contract_id: '',
      service_info_id: '',
      cert_type_id: '',
      ca_cert_chain: '',
      period: 0,
      multi_domain_flg: false,
      twoway_flg: false,
      wildcard_flg: false
    },
    resolver: yupResolver(ACME_EDIT_FORM)
  })

  const clickUpdate = async (values) => {
    await request.patch('/acme/acmeSetting', deleteNoValue({ ...values }), () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const clickDelete = async (values) => {
    await request.delete('/acme/acmeSetting', { setting_id: values.setting_id }, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const close = {
    dialog: () => setDialog({ open: false }),
    form: () => {
      form.reset()
      setObject({ expanded1: false, expanded2: false })
    }
  }

  useEffect(() => {
    if (!dialog.open) return
    request.get('/acme/acmeSetting', null, ({ body }) => {
      setObject({ acmeSettings: [...body] })
    })
  }, [dialog.open])

  const handleAcmeSettings = (e) => {
    const setting_id = Number(e.target.value)
    const as = state.acmeSettings.find((elem) => elem.setting_id === setting_id)
    form.reset()
    if (!as) {
      setObject({ expanded1: false, expanded2: false })
      return
    }

    if (as.ws_access_channel === 'ejbca-ws') {
      setObject({ expanded1: true, expanded2: false, acmeSetting: as, pulldownChangeFlg: !state.pulldownChangeFlg })
    } else if (as.ws_access_channel === 'sci-ws') {
      setObject({ expanded1: false, expanded2: true, acmeSetting: as, pulldownChangeFlg: !state.pulldownChangeFlg })
    }
    form.setValue('setting_id', as.setting_id)
    form.setValue('ws_access_channel', as.ws_access_channel)
    // form.setValue('ejbca_id', st.ejbca_id)
    // form.setValue('ca_name', st.ca_name)
    // form.setValue('ee_profile_name', st.ee_profile_name)
    // form.setValue('cert_profile_name', st.cert_profile_name)
    // form.setValue('sciws_id', st.sciws_id)
    // form.setValue('service_info_id', st.service_info_id)
    // form.setValue('cert_type_id', st.cert_type_id)
    form.setValue('contract_id', as.contract_id)
    form.setValue('period', as.period)
    form.setValue('multi_domain_flg', Boolean(as.multi_domain_flg))
    form.setValue('twoway_flg', Boolean(as.twoway_flg))
    form.setValue('wildcard_flg', Boolean(as.wildcard_flg))
  }

  const handleAcmeAccessPoint = (e) => {
    const acmeAccessPoint = e.target.value
    if (acmeAccessPoint === 'ejbca-ws') {
      setObject({ expanded1: true, expanded2: false })
    } else if (acmeAccessPoint === 'sci-ws') {
      setObject({ expanded1: false, expanded2: true })
    } else {
      setObject({ expanded1: false, expanded2: false })
    }
    gRef.pushMessages([], true)
    form.setValue('ws_access_channel', acmeAccessPoint)
  }

  return (
    <Dialog title="ACME設定の変更・削除" open={dialog.open} close={close} maxWidth="lg">
      <Loading name="load2" sx={{ top: '-34px' }} />
      <DialogContent sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField select form={form} name="setting_id" label="ACME設定名*" autoFocus onChange={handleAcmeSettings}>
              <MenuItem key={0} value="">
                {'　'}
              </MenuItem>
              {state.acmeSettings.map((item) => (
                <MenuItem key={item.setting_id} value={`${item.setting_id}`}>
                  {item.setting_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              form={form}
              name="ws_access_channel"
              label="ACME接続先*"
              onChange={handleAcmeAccessPoint}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      <Collapse in={state.expanded1} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <EjbcaSetting
          form={form}
          request={request}
          acmeSetting={state.acmeSetting}
          open={state.expanded1}
          close={close}
          editFlg
          pulldownChangeFlg={state.pulldownChangeFlg}
        />
      </Collapse>

      <Collapse in={state.expanded2} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <SciSetting
          form={form}
          request={request}
          acmeSetting={state.acmeSetting}
          open={state.expanded2}
          close={close}
          editFlg
          pulldownChangeFlg={state.pulldownChangeFlg}
        />
      </Collapse>

      <Divider variant="middle" />
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <Box>
          <LoadButton type="submit" sx={{ mr: 1 }} onClick={form.handleSubmit(clickDelete)}>
            削除
          </LoadButton>
          <LoadButton
            type="submit"
            onClick={() => {
              console.log(form.getValues())
              form.handleSubmit(clickUpdate)()
            }}
          >
            変更
          </LoadButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

AcmeSettingEdit.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func
}

export const SciSetting = ({ request, form, open, editFlg, acmeSetting, pulldownChangeFlg }) => {
  const { state, setObject } = useStateManage({ sciSettings: [], chainFlg: false })

  const handleFileSelect = (e) => {
    loadSingleFile('file-select-2', (reader, event) => {
      const pem = reader.result.trim()
      // 先頭行"-----BEGIN"、最終行"CERTIFICATE-----"
      if (!pem.match(/^-----BEGIN [\s\S]* CERTIFICATE-----$/g)) {
        alert('PEMファイル形式のCERTIFICATEでありません。')
        return
      }
      const certChain = form.getValues('ca_cert_chain')
      if (!certChain) {
        form.setValue('ca_cert_chain', pem)
      } else {
        const result = window.confirm('証明書チェーンに追記をしますか？')
        if (result) {
          form.setValue('ca_cert_chain', `${certChain}\n${pem}`)
        }
      }
      setObject({ chainFlg: true })
    })
  }

  useEffect(() => {
    if (!state.chainFlg) return
    const el = document.getElementById('ca_cert_chain')
    el.scrollTo(0, el.scrollHeight)
    setObject({ chainFlg: false })
  }, [state.chainFlg])

  useEffect(() => {
    if (!open) return
    request.get('/sciws/wsSetting', null, ({ body }) => {
      setObject({ sciSettings: [...body] })
      if (editFlg) {
        // const st = acmeSettings.find((elem) => elem.setting_id === form.getValues('setting_id'))
        form.setValue('sciws_id', acmeSetting.sciws_id)
        form.setValue('contract_id', acmeSetting.contract_id)
        form.setValue('service_info_id', acmeSetting.service_info_id)
        form.setValue('cert_type_id', acmeSetting.cert_type_id)
        form.setValue('ca_cert_chain', acmeSetting.ca_cert_chain)
      }
    })
  }, [open, pulldownChangeFlg])

  return (
    <DialogContent sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DialogText text="発行する証明書の契約とタイプを選択してください。" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField select form={form} name="sciws_id" label="SCI-WS設定名*">
            <MenuItem key={0} value="">
              {'　'}
            </MenuItem>
            {state.sciSettings.map((item) => (
              <MenuItem key={item.sciws_id} value={item.sciws_id}>
                {item.sciws_name} {item.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField form={form} name="contract_id" label="契約ID*" />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField form={form} name="service_info_id" label="サービスID*" />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField form={form} name="cert_type_id" label="証明書タイプID*" />
        </Grid>
        <Grid item xs={12}>
          <input hidden type="file" id="file-select-2" onChange={handleFileSelect} style={{ display: 'none' }} />
          <FileDragArea fileId="file-select-2" callback={handleFileSelect}>
            <TextField
              form={form}
              name="ca_cert_chain"
              label="上位証明書チェーン"
              multiline
              fullWidth
              minRows={2}
              maxRows={7}
              inputProps={{ maxLength: 32000, style: { fontFamily: 'mono' } }}
              size="middle"
            />
          </FileDragArea>
        </Grid>
        <Option form={form} />
      </Grid>
    </DialogContent>
  )
}

SciSetting.propTypes = {
  request: PropTypes.object,
  form: PropTypes.object,
  // state: PropTypes.object,
  // setObject: PropTypes.func,
  open: PropTypes.bool,
  editFlg: PropTypes.bool,
  acmeSetting: PropTypes.object,
  pulldownChangeFlg: PropTypes.bool
}

export const EjbcaSetting = ({ request, form, open, editFlg, acmeSetting, pulldownChangeFlg }) => {
  console.log('EjbcaSetting')
  const { state, setObject } = useStateManage({ ejbcas: [], cas: [], ee_profiles: [], available_cert_profiles: [] })

  const handleEjbcaChange = async (e) => {
    const ejbca_id = e.target.value
    if (!ejbca_id) return
    form.setValue('ejbca_id', ejbca_id)
    request.setOption({ loading: 'load2' })
    await request.get(
      '/ejbcas/profileSelect',
      { ejbca_id },
      ({ body }) => {
        const available_cert_profiles = body.ee_profiles[0].available_cert_profiles
        setObject({ cas: body.cas, ee_profiles: body.ee_profiles, available_cert_profiles })
        form.setValue('ca_name', body.cas[0].ca_name)
        form.setValue('ee_profile_name', body.ee_profiles[0].ee_profile_name)
        form.setValue('cert_profile_name', available_cert_profiles[0].name)
      },
      ({ error }) => {
        setFormInEmpty(form, ['ca_name', 'ee_profile_name', 'cert_profile_name'])
        setObject({ cas: [], ee_profiles: [], available_cert_profiles: [] })
      }
    )
  }

  const handleEeProfChange = async (e) => {
    const eeProfileName = e.target.value
    const eeProfile = state.ee_profiles.find((elem) => elem.ee_profile_name === eeProfileName)
    setObject({ available_cert_profiles: eeProfile.available_cert_profiles })
    form.setValue('ee_profile_name', eeProfileName)
    form.setValue('cert_profile_name', eeProfile.available_cert_profiles[0].name)
  }

  useEffect(() => {
    if (!open) return
    if (editFlg) {
      request.get('/ejbcas/managedDb/list', null, async ({ body }) => {
        setObject({ ejbcas: [...body] })
        await request.get('/ejbcas/profileSelect', { ejbca_id: acmeSetting.ejbca_id }, (res) => {
          const eeProfile = res.body.ee_profiles.find((elem) => elem.ee_profile_name === acmeSetting.ee_profile_name)
          setObject({
            cas: res.body.cas,
            ee_profiles: res.body.ee_profiles,
            available_cert_profiles: eeProfile.available_cert_profiles
          })
          form.setValue('ejbca_id', acmeSetting.ejbca_id)
          form.setValue('ca_name', acmeSetting.ca_name)
          form.setValue('ee_profile_name', acmeSetting.ee_profile_name)
          form.setValue('cert_profile_name', acmeSetting.cert_profile_name)
        })
      })
    } else {
      request.get('/ejbcas/managedDb/list', null, async ({ body }) => {
        setObject({ ejbcas: [...body] })
      })
    }
  }, [open, pulldownChangeFlg])

  return (
    <DialogContent sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DialogText text="発行する証明書のCAとプロファイルを選択してください。" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField select form={form} name="ejbca_id" label="EJBCAサーバ*" onChange={handleEjbcaChange}>
            {state.ejbcas.map((item) => (
              <MenuItem key={item.ejbca_id} value={`${item.ejbca_id}`}>
                {item.remote_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField select form={form} name="ca_name" label="CA名*">
            {state.cas.map((item) => (
              <MenuItem key={item.ca_id} value={item.ca_name}>
                {item.ca_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField select form={form} name="ee_profile_name" label="EEプロファイル*" onChange={handleEeProfChange}>
            {state.ee_profiles.map((item) => (
              <MenuItem key={item.ee_id} value={item.ee_profile_name}>
                {item.ee_profile_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField select form={form} name="cert_profile_name" label="証明書プロファイル*">
            {state.available_cert_profiles.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Option form={form} />
      </Grid>
    </DialogContent>
  )
}
EjbcaSetting.propTypes = {
  request: PropTypes.object,
  form: PropTypes.object,
  open: PropTypes.bool,
  editFlg: PropTypes.bool,
  acmeSetting: PropTypes.object,
  pulldownChangeFlg: PropTypes.bool
}

export const Option = ({ form }) => (
  <>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={6} sm={3}>
      <TextField
        form={form}
        name="period"
        label="有効期間（月単位）*1"
        type="number"
        InputProps={{ inputProps: { min: 1, max: 60 } }}
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
    <Grid item xs={6} sm={3}>
      <Checkbox form={form} name="multi_domain_flg" label="マルチドメイン証明書" />
    </Grid>
    <Grid item xs={6} sm={3}>
      <Checkbox form={form} name="twoway_flg" label="2Wayサーバ証明書" />
    </Grid>
    <Grid item xs={6} sm={3}>
      <Checkbox form={form} name="wildcard_flg" label="ワイルドカード証明書" />
    </Grid>
  </>
)
Option.propTypes = { form: PropTypes.object }
