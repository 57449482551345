import { Alert, Container, Grid } from '@mui/material'
import AccountInfo from 'src/contents/myAccount/AccountInfo'
import LoginHistory from 'src/contents/myAccount/LoginHistory'
import { MainProvider } from 'src/util/'
import { MainContent } from 'src/components'
import { useState } from 'react'

const MyAccount = () => {
  console.log('Account')
  return (
    <MainContent title="Account | M System">
      <Container maxWidth="false">
        <MainProvider>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AccountInfo />
            </Grid>
            <Grid item xs={12}>
              <LoginHistory />
            </Grid>
          </Grid>
        </MainProvider>
      </Container>
    </MainContent>
  )
}

export default MyAccount
