import { Button, Card, Checkbox, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, StyledCell, HeaderBox, FooterBox } from 'src/components'
import { useApi, useContract, diffForm, createDiffParams, useStateManage, isSuperContract } from 'src/util/'

const ApiRouteUsable = ({ selectedGroup }) => {
  const request = useApi()
  const contract = useContract()
  const { state, setObject } = useStateManage({ response: {}, open: false, form: [] })

  const initTable = () => {
    if (selectedGroup.permission_group_id) {
      request.get(
        '/apiRoute/usable',
        { permission_group_id: selectedGroup.permission_group_id, contract_id: selectedGroup.contract_id },
        ({ body }) => {
          setObject({ form: [...body], response: body })
        }
      )
    } else {
      // 契約ID選択時に初期化
      setObject({ form: [], respons: {} })
    }
  }
  useEffect(initTable, [selectedGroup.permission_group_id])

  const handleDiffForm = (id, name, value) => setObject({ form: diffForm(id, name, value, state.response, state.form) })
  const handleDiffCheckbox = (e) => handleDiffForm(e.target.id, e.target.name, e.target.checked)

  const submitUpdate = (e) => {
    const reqestParams = createDiffParams(e, state.form, ['permission_group_id', 'route_id'], ['usable'])
    if (!reqestParams.length) return
    request.put(
      '/apiRoute/bulk',
      { api_route_array: reqestParams, contract_id: selectedGroup.contract_id },
      () => {
        setObject({ open: true })
        initTable()
      },
      () => initTable()
    )
  }

  return (
    <Card>
      <HeaderBox title="使用可能API">{/* <MoreIconButton itemList={itemList} /> */}</HeaderBox>
      {/* <Divider /> */}
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <StyledCell>ID</StyledCell>
              <StyledCell>メソッド</StyledCell>
              <StyledCell sx={{ minWidth: 100 }}>APIルート</StyledCell>
              <StyledCell sx={{ minWidth: 100 }}>説明</StyledCell>
              {contract.isSuper() && <StyledCell align="center">契約割当</StyledCell>}
              <StyledCell align="center">使用可</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.form.map((row, index) => (
              <TableRow hover key={row.route_id}>
                <StyledCell>{row.route_id}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.method}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.route_path}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.description}</StyledCell>
                {contract.isSuper() && (
                  <StyledCell align="center" sx={{ wordBreak: 'break-all' }}>
                    {row.is_contract_ctrl ? '可' : ''}
                  </StyledCell>
                )}
                <StyledCell align="center" turnOnBgColor={row.usable_diff} sx={{ p: '0' }}>
                  <Checkbox
                    id={index + ''}
                    name="usable"
                    checked={row.usable}
                    value={row.usable}
                    disabled={
                      row.not_changeable ||
                      (isSuperContract(selectedGroup.contract_id) ? false : row.is_contract_ctrl ? false : true)
                    }
                    color="secondary"
                    onChange={handleDiffCheckbox}
                  />
                </StyledCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {state.form.length !== 0 && (
        <FooterBox>
          <Button type="submit" onClick={submitUpdate} variant="contained">
            更新
          </Button>
          <Dialog title="使用可能APIルートの更新" open={state.open} close={() => setObject({ open: false })}>
            使用可能APIルートが更新されました。
          </Dialog>
        </FooterBox>
      )}
    </Card>
  )
}

ApiRouteUsable.propTypes = {
  selectedGroup: PropTypes.object
}

export default ApiRouteUsable
