import React, { useEffect } from 'react'
import _ from 'lodash'
import {
  Card,
  Box,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableHead,
  Dialog as DialogTag,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button,
  Paper,
  IconButton
} from '@mui/material'
import { Dialog } from 'src/components/show/Dialog'
import { StyledCell } from 'src/components/table/StyledCell'
import CloseIcon from '@mui/icons-material/Close'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const TH = styled('th')(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
  // color: theme.palette.common.white,
  color: '#1e293b',
  fontWeight: '500',
  lineHeight: '1.43',
  borderLeftWidth: '1px',
  borderRightWidth: '1px',
  borderBottomWidth: '1px',
  padding: '6px 8px'
}))

const TD = styled('td')`
  line-height: 1.43;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-width: 1px;
  padding: 6px 8px;
`

export const DialogTable = ({ title, open, onClose, list }) => {
  console.log('DialogTable')
  const array1 = list.slice(0, Math.ceil(list.length / 2))
  const array2 = list.slice(Math.ceil(list.length / 2))

  return (
    <Dialog title={title} open={open} close={onClose} maxWidth="lg">
      <DialogContent sx={{ py: 3, px: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Table sx={{ borderCollapse: 'separate', borderTopWidth: '1px', borderRightWidth: '1px' }} size="small">
              <TableBody>
                {array1.map((row, i = 0) => (
                  <TableRow key={i}>
                    <TH width="25%" height="42px">
                      {row.th}
                    </TH>
                    <TD>{row.td}</TD>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} md={6}>
            <Table sx={{ borderCollapse: 'separate', borderTopWidth: '1px', borderRightWidth: '1px' }} size="small">
              <TableBody>
                {array2.map((row, i = 0) => (
                  <TableRow key={i}>
                    <TH width="25%" height="42px">
                      {row.th}
                    </TH>
                    <TD>{row.td}</TD>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

DialogTable.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  list: PropTypes.array.isRequired
}
