import { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { Alert as AlertTag, Input, Snackbar } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Alert } from 'src/components'
import { MENU_ROUTE_PUBLIC, useMenuRout } from 'src/Rout'
import { useApi, useAppRef, useGlobal, CALL } from 'src/util/'
import DashboardSidebar from './DashboardSidebar'
import DashboardNavbar from './DashboardNavbar'

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden'
}))

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: { pl: 0 } // ウィンドウサイズ変更用
}))

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflowY: 'scroll'
})

function SnackbarAria() {
  const [state, setState] = useState({ open: false, severity: undefined, msg: '' })
  console.log('SnackbarAria: ', state)

  const ref = useAppRef()
  ref.setCallBack(CALL.SNACKBAR, (severity, msg) => setState({ open: true, severity, msg }))

  return (
    <Snackbar
      // message={state.msg}
      open={state.open}
      onClose={() => setState({ open: false, msg: state.msg })}
      direction="down"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      {/* <AlertTag severity={state.severity}>{state.msg}</AlertTag> */}
      <AlertTag severity="error" sx={{ width: '100%' }}>
        {state.msg}
      </AlertTag>
    </Snackbar>
  )
}

function PageAlert() {
  const gRef = useGlobal(CALL.PAGE_ALERT)

  return (
    <>
      {gRef.getActiveDialog() === '' &&
        gRef.getMessages().map((message, i = 0) => (
          <Alert
            key={i}
            open={message.open}
            onClose={() => {
              gRef.closeMessage(CALL.PAGE_ALERT, i)
            }}
            severity={message.severity}
          >
            {message.body}
          </Alert>
        ))}
    </>
  )
}

function DashboardLayout() {
  console.log('DashboardLayout: ')

  const gRef = useGlobal(CALL.DASHBOARD_LAYOUT)
  const user = gRef.getUser()
  const request = useApi()
  const initializePermission = useMenuRout()

  const [isMobileNavOpen, setMobileNavOpen] = useState(false)

  const handleBeforeUnloadEvent = (e) => {
    if (user.token) sessionStorage.setItem('token', user.token)
    // e.returnValue = ''
  }

  if (!user.token) {
    const token = sessionStorage.getItem('token')
    if (token) {
      user.token = sessionStorage.getItem('token')
      sessionStorage.removeItem('token')
    }
  }

  useEffect(() => {
    console.log('DashboardLayout useEffect')
    if (user.loginStatus !== 1) {
      // F5押下時
      if (user.token) {
        request.get('/myInfo', { resp_menues: true }, ({ body }) => {
          initializePermission(
            body.data.permission_group_id,
            body.menu,
            body.allowedMenuPaths,
            window.location.pathname
          )
        })
      } else if (window.location.pathname === '/app/publicInfo') {
        gRef.setDispMenus(MENU_ROUTE_PUBLIC)
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnloadEvent)
    return () => window.removeEventListener('beforeunload', handleBeforeUnloadEvent)
  }, [])

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} user={user} />
      <DashboardSidebar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <DashboardLayoutWrapper>
        <SnackbarAria />
        <DashboardLayoutContent id="contents">
          <PageAlert />
          <Outlet />
        </DashboardLayoutContent>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  )
}

export default DashboardLayout
