import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AppBar, Badge, Box, Hidden, Input, IconButton, Toolbar } from '@mui/material'
import { Menu as MenuIcon, Input as InputIcon, Notifications as NotificationsIcon } from '@mui/icons-material'
import { useApi, useGlobal, CALL } from 'src/util'
import Logo from './Logo'

// useNavigate による不要な描画抑止のため、関数を分離。
const HiddenNavigate = () => {
  // console.log('HiddenNavigate: ')
  const navigate = useNavigate()
  const request = useApi()
  const gRef = useGlobal()

  return (
    <>
      <Input id="logout" type="hidden" onClick={() => navigate('/login')} />
      <Input
        id="logoutRequest"
        type="hidden"
        onClick={() => {
          request.setOption({ clearMessage: true })
          request.post('/logout', null)
        }}
      />
      <Input
        id="pageReflesh"
        type="hidden"
        onClick={(e) => {
          gRef.pushMessages([], true)
          // gRef.call(CALL.PAGE_ALERT)
          navigate('/app/pageReflesh')
        }}
      />
    </>
  )
}

const DashboardNavbar = ({ onMobileNavOpen, user, ...rest }) => {
  // console.log('DashboardNavbar: ')
  const [notifications] = useState([])

  return (
    <AppBar sx={{ backgroundColor: 'background.custom1' }} elevation={0} {...rest}>
      <HiddenNavigate />
      <Toolbar>
        <Logo />
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          {user.loginStatus === 1 && (
            <IconButton color="inherit" size="large">
              <Badge badgeContent={notifications.length} color="info">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
          {user.loginStatus === 1 ? (
            <IconButton color="inherit" size="large" onClick={() => document.getElementById('logoutRequest').click()}>
              <InputIcon />
            </IconButton>
          ) : (
            <IconButton color="inherit" size="large" href="https://www.secomtrust.net/">
              <InputIcon />
            </IconButton>
          )}
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  user: PropTypes.object
}

export default DashboardNavbar
