/* eslint-disable */
import { useOutletContext } from 'react-router-dom'
import { deleteNoValue } from './commons'
import { setLoading } from 'src/components/show/Loading'
import { useGlobal } from 'src/util/appContext'

/**
 * メッセージオブジェクトを生成します。
 * @param {'success' | 'info' | 'warning' | 'error'} severity
 * @param {string} body
 * @param {boolean} open
 * @return Object
 */
export function createMessage(severity, body, open) {
  return { severity, body, open: open || true }
}

/** @param {User} user @param {User} newUser @description 引数に何も指定しない場合初期化する。 */
export function createUser() {
  return {
    id: undefined,
    name: undefined,
    loginStatus: 0,
    token: undefined,
    contractId: undefined
  }
}

/**
 * @typedef {Object} RespData
 * @property {User} user - ユーザ
 * @property {any} body - レスポンスボディ
 * @property {string} error - レスポンスエラー
 * @property {any[]} messages - レスポンスメッセージ
 *
 * @return {RespData}
 */
export function createRespData() {
  return {
    user: createUser(),
    body: undefined,
    error: '', // resp.status + ': ' + resp.statusText
    messages: []
  }
}

// const respLocation = [location.pathname]
// function checkMessageClear(opts, global) {
//   if (opts.clearMessage) {
//     global.messages = []
//     return
//   }
//   const previous = respLocation.shift()
//   respLocation.push(location.pathname)
//   if (previous !== location.pathname) {
//     global.messages = []
//   }
// }

const checkReload = () => {
  if (sessionStorage.getItem('reload') && sessionStorage.getItem('reload') === 'true') {
    sessionStorage.removeItem('reload')
    window.location.reload(true)
  }
}

const _createRespData = async (resp) => {
  let respData = createRespData()
  if (!resp.ok) respData.error = resp.status + ': ' + resp.statusText

  respData.user = JSON.parse(decodeURIComponent(resp.headers.get('x-user'))) || {}
  const type = resp.headers.get('Content-Type')
  // for (const item of resp.headers.values()) { console.log(item)}
  if (type) {
    if (type.startsWith('application/json')) {
      const json = await resp.json()
      respData.body = json
    } else if (type.startsWith('application/')) {
      respData.body = await resp.blob()
      const xWarnMsg = resp.headers.get('x-warn-msg') && decodeURIComponent(resp.headers.get('x-warn-msg'))
      if (xWarnMsg) respData.messages.push(createMessage('warning', xWarnMsg))
    }
  }

  if (!resp.ok) {
    if (respData.body) {
      if (respData.body.type !== 'LoginError') {
        respData.messages.push(createMessage('error', respData.body.type + ': ' + respData.body.message))
      }
    } else {
      respData.messages.push(createMessage('error', 'APIへの接続でエラーが発生しました。' + respData.error))
    }
  }

  if (respData.user.loginStatus === 2) {
    respData.messages.push(createMessage('success', 'ログアウトしました。'))
  } else if (respData.user.loginStatus === 9) {
    respData.messages.push(createMessage('warning', 'セッションが取得できなかったため、ログアウトしました。'))
  } else if (resp.status === 504 || resp.status === 403) {
    respData.user.loginStatus = 9
  }
  // if (user.id !== respData.user.id && location.pathname !== '/login') {
  //   // 同一ブラウザ、複数タブでユーザ切替。公開URLにリクエスト時
  //   alert('ログインユーザIDが切り替えられました。ページを再読み込みします。')
  //   location.reload()
  // }
  return respData
}

/**
 * @param {Object} opts - オプション
 * @param {boolean} opts.clearMessage - メッセージの削除
 */
export const useApi = (opts = {}) => {
  const gRef = useGlobal()

  const returnProcess = async (respData, success, error) => {
    checkReload()
    gRef.setUser(respData.user)

    // 強制ログアウト遷移
    if (respData.user.loginStatus >= 2) {
      gRef.pushMessages(respData.messages, true)
      const logout = document.getElementById('logout')
      if (logout) logout.click()
      return
    }

    // メッセージ差分あれば描画
    gRef.pushMessages(respData.messages, opts.clearMessage)

    // コールバックがあれば実行
    if (!respData.error) {
      if (success) success(respData)
    } else {
      if (error && typeof error === 'function' && respData.body) error(respData)
    }
    // スクロールトップ
    // if (global.messages.length) {
    if (gRef.getMessages().length) {
      const contents = document.getElementById('contents')
      contents && contents.scrollTo(0, 0)
    }
  }

  const fetchApi = async (method, path, sendData, option) => {
    const headers = { location: location.pathname + location.search, token: '' }
    if (gRef.getUser().token) {
      headers.token = gRef.getUser().token
    }
    if (option?.json) {
      headers['Content-Type'] = 'application/json'
      if (sendData) sendData = JSON.stringify(sendData)
    }
    console.log('-', method, '/api' + path, '-', headers, '-', sendData)
    const result = await fetch('/api' + path, {
      method: method,
      credentials: 'same-origin',
      headers: headers,
      body: sendData
    })
    return result
  }

  const wrap = async (method, path, sendData, success, error) => {
    let response, loadingName
    try {
      if (opts.loading) {
        loadingName = opts.loading
        await setLoading(loadingName, true)
        opts.loading = undefined
      }
      if (method === 'GET') {
        let query = ''
        const sendQuery = deleteNoValue({ ...sendData })
        if (sendQuery) query = '?' + new URLSearchParams(sendQuery)
        response = await fetchApi('GET', path + query, null, { json: true })
      } else if (method === 'UPLOAD') {
        response = await fetchApi('POST', path, sendData)
      } else {
        response = await fetchApi(method, path, sendData, { json: true })
      }
      const respData = await _createRespData(response)
      await returnProcess(respData, success, error)
      return respData.body
    } catch (e) {
      console.error('APIリクエストエラー!!', e)
      const respData = createRespData()
      respData.messages.push(createMessage('error', 'APIへのリクエストに失敗しました。' + e.message))
      respData.user.loginStatus = 9
      await returnProcess(respData)
    } finally {
      if (loadingName) await setLoading(loadingName, false)
    }
  }

  const request = {
    /** @param {string} path  @param {object} sendData @param {ResponseAfterFunction} success @param {ResponseAfterFunction} error @return {Promise<ResponseData>} */
    get: (path, sendData, success, error) => wrap('GET', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {ResponseAfterFunction} success @param {ResponseAfterFunction} error @return {Promise<ResponseData>} */
    post: (path, sendData, success, error) => wrap('POST', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {ResponseAfterFunction} success @param {ResponseAfterFunction} error @return {Promise<ResponseData>} */
    patch: (path, sendData, success, error) => wrap('PATCH', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {ResponseAfterFunction} success @param {ResponseAfterFunction} error @return {Promise<ResponseData>} */
    put: (path, sendData, success, error) => wrap('PUT', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {ResponseAfterFunction} success @param {ResponseAfterFunction} error @return {Promise<ResponseData>} */
    delete: (path, sendData, success, error) => wrap('DELETE', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {ResponseAfterFunction} success @param {ResponseAfterFunction} error @return {Promise<ResponseData>} */
    upload: (path, formData, success, error) => wrap('UPLOAD', path, formData, success, error),

    setOption: (_option) => (opts = { ...opts, ..._option })
  }
  return request
}
