import { Box, Card, Grid, MenuItem, SvgIcon } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useApi, useStateManage, useUser, useContract, useGlobal, useInit, createMessage } from 'src/util/'
import { useForm } from 'react-hook-form'
import { TextField, ContractAutocomplete } from 'src/components'
import PermissionGroupMore from './PermissionGroupMore'

const PermissionGroup = ({ selectedGroup, setSelectedGroup }) => {
  console.log('PermissionGroup')
  const contract = useContract()
  const gRef = useGlobal()
  const init = useInit()
  const form = useForm({
    defaultValues: { contract_id: null, permission_group_id: '' }
  })

  const handlePermissionGroup = ({ target }) => {
    const _permissionGroupList = contract.getPermissionGroupList(form.getValues('contract_id'))
    const selected = _permissionGroupList.find((item) => item.permission_group_id === target.value)
    if (gRef.getPermissionGroupId() === selected.permission_group_id) {
      gRef.pushMessage(
        'warning',
        '自身が所属する権限グループの使用可能権限を削除すると、そのユーザ自身も扱えなくなります。'
      )
    } else {
      gRef.pushMessages([], true)
    }
    form.setValue('permission_group_id', target.value)
    setSelectedGroup({
      permission_group_id: selected.permission_group_id,
      permission_group_name: selected.permission_group_name,
      description: selected.description
    })
  }

  const handleContract = (e, value) => {
    if (!e) return
    form.setValue('permission_group_id', '')
    setSelectedGroup({
      contract_id: form.getValues('contract_id'),
      permission_group_id: ''
    })
  }

  const initialize = (opts) => {
    if (opts) {
      init.setPermissionGroup({ force: true }).then((list) => {
        let permissionGroup
        if (opts.editedFlg) {
          permissionGroup = list.find((item) => item.permission_group_id === form.getValues('permission_group_id'))
        } else {
          // opts.createdFlg の場合、最新レコード使用
          permissionGroup = list[list.length - 1]
        }
        form.setValue('contract_id', permissionGroup.contract_id)
        form.setValue('permission_group_id', permissionGroup.permission_group_id)
        setSelectedGroup({
          contract_id: permissionGroup.contract_id,
          permission_group_id: permissionGroup.permission_group_id,
          permission_group_name: permissionGroup.permission_group_name,
          description: permissionGroup.description
        })
      })
    } else {
      init.setPermissionGroup()
    }
  }

  useEffect(initialize, [])

  return (
    <div>
      <Card sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
        <Box
          py={1}
          px={2}
          sx={{ minHeight: '64px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <SvgIcon color="action">
              <SearchIcon />
            </SvgIcon>
            {contract.isSuper() && (
              <Grid item xs={6} sm={6} sx={{ alignItems: 'center' }}>
                <ContractAutocomplete
                  form={form}
                  contract={contract}
                  onChange={handleContract}
                  sx={{ mx: 1 }}
                  fullWidth
                  label="契約の選択*"
                  size="small"
                />
              </Grid>
            )}
            <Grid item xs={6} sm={contract.isSuper() ? 6 : 12} sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
              <TextField
                select
                form={form}
                name="permission_group_id"
                label="権限グループの選択*"
                sx={{ mx: 1 }}
                size="small"
                onChange={handlePermissionGroup}
              >
                {contract.getPermissionGroupList(form.getValues('contract_id')).map((item) => (
                  <MenuItem key={item.permission_group_id} value={item.permission_group_id}>
                    {item.permission_group_name}
                    {item.description && '（' + item.description + '）'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <PermissionGroupMore init={initialize} selectedGroup={selectedGroup} />
          </Grid>
        </Box>
      </Card>
    </div>
  )
}

PermissionGroup.propTypes = {
  selectedGroup: PropTypes.object,
  setSelectedGroup: PropTypes.func
}

export default PermissionGroup
