import { Button, Card, Checkbox, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, StyledCell, HeaderBox, FooterBox } from 'src/components'
import { useApi, useContract, diffForm, createDiffParams, useStateManage, isSuperContract } from 'src/util/'
import { useMenuRout } from 'src/Rout'

const AppMenuUsable = ({ selectedGroup }) => {
  const initializePermission = useMenuRout()
  const request = useApi()
  const contract = useContract()
  const { state, setObject } = useStateManage({ response: {}, open: false, form: [] })

  const initTable = () => {
    if (selectedGroup.permission_group_id) {
      request.get(
        '/appMenu/showable',
        { permission_group_id: selectedGroup.permission_group_id, contract_id: selectedGroup.contract_id },
        ({ body }) => {
          setObject({ form: [...body], response: body })
        }
      )
    } else {
      // 契約ID選択時に初期化
      setObject({ form: [], response: {} })
    }
  }
  useEffect(initTable, [selectedGroup.permission_group_id])

  const handleDiffForm = (id, name, value) => {
    const deff = diffForm(id, name, value, state.response, state.form)
    setObject({ form: deff })
  }
  const handleDiffCheckbox = (e) => handleDiffForm(e.target.id, e.target.name, e.target.checked)

  const submitUpdate = (e) => {
    const reqestParams = createDiffParams(e, state.form, ['permission_group_id', 'app_menu_id'], ['showable'])
    if (!reqestParams.length) return
    request.put(
      '/appMenu/bulk',
      { app_menu_array: reqestParams, contract_id: selectedGroup.contract_id },
      () => {
        setObject({ open: true })
        request.get('/myInfo', { resp_menues: true }, ({ body }) => {
          initializePermission(body.data.permission_group_id, body.menu, body.allowedMenuPaths)
          initTable()
        })
      },
      () => initTable()
    )
  }
  return (
    <Card>
      <HeaderBox title="使用可能アプリメニュー">{/* <MoreIconButton itemList={itemList} /> */}</HeaderBox>
      <TableContainer>
        {/* <PerfectScrollbar> */}
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <StyledCell>ID</StyledCell>
              <StyledCell>親ID</StyledCell>
              <StyledCell sx={{ minWidth: 100 }}>メニュータイトル</StyledCell>
              <StyledCell sx={{ minWidth: 100 }}>パス</StyledCell>
              {contract.isSuper() && <StyledCell align="center">契約割当</StyledCell>}
              <StyledCell align="center">使用可</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.form.map((row, index) => (
              <TableRow hover key={row.app_menu_id}>
                <StyledCell>{row.app_menu_id}</StyledCell>
                <StyledCell>{row.parent_id}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.menu_title}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.path}</StyledCell>
                {contract.isSuper() && (
                  <StyledCell align="center" sx={{ wordBreak: 'break-all' }}>
                    {row.is_contract_ctrl ? '可' : ''}
                  </StyledCell>
                )}
                <StyledCell align="center" turnOnBgColor={row.showable_diff} sx={{ p: '0' }}>
                  <Checkbox
                    id={index + ''}
                    name="showable"
                    checked={row.showable}
                    value={row.showable}
                    disabled={
                      row.not_changeable ||
                      (isSuperContract(selectedGroup.contract_id) ? false : row.is_contract_ctrl ? false : true)
                    }
                    color="secondary"
                    onChange={handleDiffCheckbox}
                  />
                </StyledCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* </PerfectScrollbar> */}
      </TableContainer>
      {state.form.length !== 0 && (
        <FooterBox>
          <Button type="submit" onClick={submitUpdate} variant="contained">
            更新
          </Button>
          <Dialog title="使用可能アプリメニューの更新" open={state.open} close={() => setObject({ open: false })}>
            使用可能アプリメニューが更新されました。
          </Dialog>
        </FooterBox>
      )}
    </Card>
  )
}

AppMenuUsable.propTypes = {
  selectedGroup: PropTypes.object
}

export default AppMenuUsable
