import { Box, Snackbar as SnackbarTag, Slide } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'

export const Snackbar = ({ message, open, onClose, direction, time, children, ...props }) => {
  const [state, setState] = useState({
    open: false,
    Transition: (_props) => <Slide {..._props} direction={direction || 'up'} />
  })

  if (open !== state.open) {
    setState({ ...state, open })
  }

  return (
    <SnackbarTag
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={time || 1800}
      TransitionComponent={state.Transition}
      open={open}
      onClose={onClose}
      message={message}
      key="bottom-right"
      sx={{ width: '400px' }}
      {...props}
      ContentProps={{
        sx: { background: '#009FA8' }
      }}
    >
      {children}
    </SnackbarTag>
  )
}

Snackbar.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  direction: PropTypes.string,
  time: PropTypes.number,
  children: PropTypes.any
}
