import { Autocomplete, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import { useState, useReducer } from 'react'
import { Controller } from 'react-hook-form'
import { useApi } from 'src/util/'
import { StyledTextField, TextField } from './StyledTextField'

const _set = (state, obj) => {
  return { ...state, ...obj }
}
export const AutocompleteText = ({ name, setValue, reqParams, optionPropName, ...props }) => {
  const [state, setState] = useReducer(_set, { init: true, loading: false, options: [] })
  const [value, setInputValue] = useState('')
  const request = useApi()

  if (!optionPropName) optionPropName = name

  const _setOepn = () => {
    if (!state.init) return
    setState({ init: false, loading: true })
    request[reqParams.method](
      reqParams.path,
      reqParams.sendData,
      ({ body }) => {
        setState({ options: [...body], loading: false })
      },
      () => {
        setState({ loading: false })
      }
    )
  }

  return (
    <Autocomplete
      freeSolo
      // autoSelect ※タブフォーカスで不具合あり
      onOpen={_setOepn}
      inputValue={value}
      onInputChange={(e, newValue) => {
        setValue(name, newValue)
        setInputValue(newValue)
      }}
      options={state.options.map((option) => option[optionPropName])}
      sx={{ '& .MuiOutlinedInput-root': { paddingTop: '5px', paddingBottom: '5px' } }}
      renderInput={(params) => (
        <StyledTextField
          {...props}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}
AutocompleteText.propTypes = {
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  reqParams: PropTypes.object.isRequired,
  optionPropName: PropTypes.string
}

export const AutocompleteText2 = ({ form, name, options, onChange, ...props }) => (
  <Controller
    name={name}
    control={form.control}
    render={({ field, fieldState, formState }) => (
      <Autocomplete
        // freeSolo
        disableClearable
        name={name}
        // inputRef={field.ref}
        value={field.value}
        // isOptionEqualToValue={(option, value) => {option === value}}
        onInputChange={(e, newValue) => {
          onChange ? onChange(e, newValue) : form.setValue(name, newValue, { shouldValidate: true })
        }}
        options={options} // [{ label: 'AAA', id: 1 }, { label: 'BBB', id: 2 }]
        sx={{ '& .MuiOutlinedInput-root': { paddingTop: '5px', paddingBottom: '5px' } }}
        renderInput={(params) => (
          <StyledTextField {...params} {...props} error={fieldState.invalid} helperText={fieldState.error?.message} />
        )}
      />
    )}
  />
)

AutocompleteText2.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func
}

export const ContractAutocomplete = ({ form, contract, onChange, ...props }) => {
  const [label, setLabel] = useState(null)
  const fieldState = form.getFieldState('contract_id')

  return (
    <Autocomplete
      disableClearable
      options={contract.getContractDispList()}
      value={label}
      onChange={(event, value) => {
        setLabel(value ? value : null)
        form.setValue('contract_id', value ? value.substring(0, value.indexOf(':')) : '', { shouldValidate: true })
        if (onChange) onChange(event, value)
      }}
      sx={{ '& .MuiOutlinedInput-root': { paddingTop: '5px', paddingBottom: '5px' } }}
      renderInput={(params) => (
        <StyledTextField {...params} {...props} error={fieldState.invalid} helperText={fieldState.error?.message} />
      )}
    />
  )
}

ContractAutocomplete.propTypes = {
  form: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  onChange: PropTypes.func
}

// export const ContractAutocomplete = ({ form, contract, onChange, ...props }) => {
//   const [inputValue, setInputValue] = useState('')

//   return (
//     <Controller
//       name="fruit"
//       control={form.control}
//       render={({ field, fieldState, formState }) => (
//         <Autocomplete
//           {...field}
//           options={[
//             { label: 'りんご', value: 'apple' },
//             { label: 'ばなな', value: 'banana' },
//             { label: 'さくらんぼ', value: 'cherry' }
//           ]}
//           getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
//           // getOptionLabel={(option) => option.label}
//           renderOption={(props2, option) => (
//             <li {...props2} key={option.value}>
//               {option.label}
//             </li>
//           )}
//           isOptionEqualToValue={(option, value) => {
//             option.label === value
//           }}
//           value={inputValue}
//           inputValue={inputValue}
//           onInputChange={(event, newInputValue, reason) => {
//             if ('input') setInputValue(newInputValue)
//           }}
//           // onChange={(event, value) => field.onChange(value ? value.value : null)}
//           onChange={(event, value) => {
//             field.onChange(value ? value.value : null)
//             setInputValue(value ? value.label : '')
//           }}
//           renderInput={(params) => <StyledTextField {...params} label="フルーツを選択" />}
//         />
//       )}
//     />
//   )
// }
