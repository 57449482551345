import { createContext, useContext, useState, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'
import { isEqual, isEqualDiff } from 'src/util/commons'
import { createMessage } from 'src/util/api'
import { useApi } from './api'
import { CallUtils, CALL, FIELD } from './callUtils'

const SUPER_CONTRACT_ID = 0
const AppContext = createContext({})

/**
 * AppRef情報
 * @typedef {Object} AppRef
 * @property {GlobalState} global
 * @property {GlobalState} prev
 * @property {Permission} permission
 * @property {string} activeDialog
 * @property {string} renderPoint
 * @property {Object} values
 * @property {Object} callBacks
 */

export function AppProvider({ children }) {
  const ref = useRef({
    global: {
      user: {},
      messages: []
    },
    prev: {
      user: {},
      messages: []
    },
    permission: {
      permission_group_id: -1,
      dispMenus: [],
      allowedMenuPaths: {}
    },
    activeDialog: '',
    renderPoint: '999',
    values: {},
    callBacks: {}
  })
  const value = { ref }
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
AppProvider.propTypes = { children: PropTypes.any }

/**
 * AppContext内の共有参照を利用するメソッドを提供する。
 */
export function useAppRef() {
  const ctxt = useContext(AppContext)
  /** @type {AppRef} */
  const curt = ctxt.ref.current

  const method = {
    get: (field) => {
      return curt.values[field]
    },
    set: (field, value) => {
      curt.values[field] = value
    },
    getGlobal: (field) => {
      return curt.global[field]
    },
    setGlobal: (field, value) => {
      curt.global[field] = value
    },
    setCallBack: (cbName, cb) => {
      curt.callBacks[cbName] = cb
    },
    call: (cbName, args) => {
      return CallUtils.call(curt, cbName, args)
    }
  }
  return method
}

/**
 * グローバル変数として、useRef を利用したメソッド群を提供する
 *
 * @param { string } renderPoint
 *  レンダリング（Stete）ポイントとしても利用する場合に一意となるキー名を指定する。
 *  globalの参照のみであれば指定は不要。
 * @see useRef
 * @see useState
 */
export function useGlobal(renderPoint) {
  const ctxt = useContext(AppContext)
  /** @type {AppRef} */
  const curt = ctxt.ref.current

  const _global = curt.global
  const _prev = curt.prev
  const _permission = curt.permission
  // const _localRef = useRef(JSON.parse(JSON.stringify(_global)))
  // let _local = _localRef.current

  const method = {
    /** @param {User} user */
    setUser: (user) => {
      const { token, ...other } = _prev.user
      if (!isEqual(other, user)) {
        _prev.user = { token: _global.user.token, ..._prev.user, ...user }
        _global.user = JSON.parse(JSON.stringify(_prev.user))
        // CallUtils.call(curt, CALL.DASHBOARD_LAYOUT)
      }
    },

    getUser: () => _global.user,
    isSuperUser: () => SUPER_CONTRACT_ID === _global.user.contractId,

    getMessages: () => _global.messages,

    /** @param {string[]} messages @param {boolean | undefined} clearFlg */
    pushMessages: (messages, clearFlg) => {
      if (CallUtils.isPathChange()) {
        clearFlg = true
        curt.activeDialog = ''
      }

      if (clearFlg) _prev.messages = []
      messages.forEach((msg) => {
        const finded = _prev.messages.find((el) => JSON.stringify(el) === JSON.stringify(msg))
        if (!finded) _prev.messages.push(msg)
      })

      /* eslint-disable no-restricted-globals */
      const pathname = location.pathname

      if (curt.activeDialog === '') {
        _global.messages = CallUtils.renderDiff(curt, CALL.PAGE_ALERT, _global.messages, _prev.messages)
      } else if (pathname === '/login') {
        _global.messages = CallUtils.renderDiff(curt, CALL.LOGIN, _global.messages, _prev.messages)
      } else {
        _global.messages = CallUtils.renderDiff(curt, curt.activeDialog, _global.messages, _prev.messages)
      }
    },

    /** @param {'success' | 'info' | 'warning' | 'error'} severity @param {string[]} message @param {boolean | undefined} clearFlg */
    pushMessage: (severity, message, clearFlg) => {
      method.pushMessages([createMessage(severity, message)], clearFlg)
    },

    /** アラート用メッセージを閉じる */
    closeMessage: (_renderPoint, idx) => {
      if (_prev.messages[idx]) {
        _prev.messages[idx].open = false
        _global.messages[idx].open = false
        CallUtils.call(curt, _renderPoint)
      }
    },

    getActiveDialog: () => curt.activeDialog,
    setActiveDialog: (dialogName) => (curt.activeDialog = dialogName),

    getPermissionGroupId: () => _permission.permission_group_id,
    setPermissionGroupId: (permission_group_id) => (_permission.permission_group_id = permission_group_id),

    getDispMenus: () => _permission.dispMenus,
    setDispMenus: (dispMenus) => (_permission.dispMenus = dispMenus),

    getAllowedMenuPaths: () => _permission.allowedMenuPaths,

    /** @param {object} allowedMenuPaths */
    renderMenuPaths: (allowedMenuPaths) => {
      _permission.allowedMenuPaths = allowedMenuPaths
      // CallUtils.call(curt, CALL.DASHBOARD_SIDEBAR) F5問題
      CallUtils.call(curt, CALL.APP)
    },

    /** @param {string} cbName @param {Function} cb */
    setCallBack: (cbName, cb) => (curt.callBacks[cbName] = cb),

    call: (cbName, args) => CallUtils.call(curt, cbName, args)
  }

  if (renderPoint) {
    const [state, setState] = useState(false)
    method.setCallBack(renderPoint, () => {
      setState(!state)
    })
  }

  return method
}

export function useUser() {
  const ctxt = useContext(AppContext)
  const curt = ctxt.ref.current

  /** @type {User} */
  const _user = curt.global.user

  const method = {
    ..._user,

    /** スーパー契約の場合trueを返す */
    isSuper: () => {
      return SUPER_CONTRACT_ID === _user.contractId
    }
  }
  return method
}

export function useInit() {
  const [toggle, setState] = useState(false)
  const ref = useAppRef()
  const request = useApi()

  const init = {
    setContract: (_opts = {}) => {
      const contractId = ref.getGlobal('user').contractId
      if (SUPER_CONTRACT_ID !== contractId || (!_opts.force && ref.get(FIELD.CONTRACT_LIST))) return
      request.get('/contractAll', null, ({ body }) => {
        ref.set(FIELD.CONTRACT_LIST, body.rows)
        ref.set(
          FIELD.CONTRACT_DISP_LIST,
          body.rows.map((row) => `${row.contract_id}:${row.contract_name}`)
        )
        setState(!toggle)
      })
    },
    setPermissionGroup: async (_opts = {}) => {
      // init: undefined, clear: null,
      const list = ref.get(FIELD.PERMISSION_GROUP_LIST)
      if (!_opts.force && list) return list
      const newList = await request.get('/permissionGroup/list', null, ({ body }) => {
        ref.set(FIELD.PERMISSION_GROUP_LIST, body)
        const map = new Map()
        body.forEach((obj) => {
          const tmp = map.get(obj.contract_id) ? map.get(obj.contract_id) : []
          map.set(obj.contract_id, tmp.concat(obj))
        })
        ref.set(FIELD.PERMISSION_GROUP_MAP, map)
      })
      if (_opts.renderFlg || list === null) setState(!toggle)
      return newList
    }
  }
  return init
}

/**
 * 契約関連の汎用オブジェクト関数群を提供するフック
 */
export function useContract() {
  const ref = useAppRef()

  const contract = {
    isSuper: () => {
      return SUPER_CONTRACT_ID === ref.getGlobal('user').contractId
    },

    getContractList: () => {
      return ref.get(FIELD.CONTRACT_LIST) || []
    },

    getContractDispList: () => {
      return ref.get(FIELD.CONTRACT_DISP_LIST) || []
    },

    cutContractDisp: (contract_disp_name) => {
      if (!contract_disp_name) return null
      const posision = contract_disp_name.indexOf(':')
      if (posision < 0) return null
      return contract_disp_name.substring(0, posision)
    },

    clearContract: () => {
      ref.set(FIELD.CONTRACT_LIST, null)
      ref.set(FIELD.CONTRACT_DISP_LIST, null)
    },

    getPermissionGroupList: (contract_id) => {
      if (SUPER_CONTRACT_ID !== ref.getGlobal('user').contractId) {
        contract_id = ref.getGlobal('user').contractId
      }
      if (contract_id === undefined) return []
      const map = ref.get(FIELD.PERMISSION_GROUP_MAP)
      return map?.get(Number(contract_id)) || []
    },

    clearPermissionGroup: () => {
      ref.set(FIELD.PERMISSION_GROUP_LIST, null)
      ref.set(FIELD.PERMISSION_GROUP_MAP, null)
    }
  }
  return contract
}

export function isSuperContract(contract_id) {
  return parseInt(contract_id, 10) === SUPER_CONTRACT_ID
}
