import { Helmet } from 'react-helmet'
import { Box, Container, Grid } from '@mui/material'
import { useState } from 'react'
import PermissionGroup from 'src/contents/permissionManage/PermissionGroup'
import AppMenuUsable from 'src/contents/permissionManage/AppMenuUsable'
import ApiRouteUsable from 'src/contents/permissionManage/ApiRouteUsable'
import { MainContent } from 'src/components'
import PropTypes from 'prop-types'
import { useApi, useStateManage, setFormInEmpty, MainProvider, useInit } from 'src/util/'

const PermissionManage = () => {
  // console.log('PermissionManage')
  useInit().setContract()
  const { state, setObject } = useStateManage({
    contract_id: '',
    permission_group_id: '',
    permission_group_name: '',
    description: ''
  })

  return (
    <MainContent title="PermissionManage | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PermissionGroup selectedGroup={state} setSelectedGroup={setObject} />
            </Grid>
            <Grid item xs={12}>
              <AppMenuUsable selectedGroup={state} />
            </Grid>
            <Grid item xs={12}>
              <ApiRouteUsable selectedGroup={state} />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default PermissionManage
