import { Box, Button, Card, CardContent, Divider, Grid, MenuItem } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { HeaderBox, TextField, AutocompleteText2, ContractAutocomplete } from 'src/components'
import { usePageControl, useContract } from 'src/util/'
import { AccountSearchMore } from './AccountSearchMore'

export const AccountSearch = () => {
  console.log('AccountSearch')
  const pc = usePageControl()
  const contract = useContract()

  const form = useForm({
    defaultValues: {
      contract_id: '',
      account_binding: '',
      account_id: '',
      status: '',
      setting_name: ''
    }
  })
  const onSubmit = (values) => {
    console.log(values)
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    form.handleSubmit(onSubmit)()
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="ACMEアカウント 検索">
          <AccountSearchMore />
        </HeaderBox>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {contract.isSuper() && (
              <Grid item xs={12} sm={6} lg={6}>
                <ContractAutocomplete form={form} contract={contract} label="契約" />
              </Grid>
            )}
            <Grid item xs={12} sm={6} lg={contract.isSuper() ? 6 : 6}>
              <TextField form={form} name="account_binding" label="ユーザID連携（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} lg={contract.isSuper() ? 5 : 6}>
              <TextField form={form} name="account_id" label="アカウントID" />
            </Grid>
            <Grid item xs={12} sm={6} lg={contract.isSuper() ? 2 : 4}>
              <TextField select form={form} name="status" label="ステータス">
                <MenuItem key={0} value="">
                  すべて
                </MenuItem>
                <MenuItem key={1} value="provisional">
                  provisional
                </MenuItem>
                <MenuItem key={2} value="valid">
                  valid
                </MenuItem>
                <MenuItem key={3} value="deactivated">
                  deactivated
                </MenuItem>
                <MenuItem key={4} value="revoked">
                  revoked
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} lg={contract.isSuper() ? 5 : 8}>
              <TextField form={form} name="setting_name" label="ACME設定名" />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, px: 2 }}>
          <Button type="submit" color="primary" variant="contained">
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default AccountSearch
