/* eslint-disable */
import { useState, useRef, useCallback, useEffect } from 'react'
import { isObject } from './commons'
import { any } from 'prop-types'

/**
 * ステートオブジェクトを管理するメソッドを提供する。
 * @template T
 * @param {T} initObj
 */
export const useStateManage = (initObj) => {
  const [_state, _setState] = useState(initObj)

  /** @type {T} */
  const state = _state
  /** @type {T} */
  const _default = JSON.parse(JSON.stringify(initObj))
  const ref = useRef(initObj)

  /**
   * Stateオブジェクトの差分で内容を更新する。
   * プロパティは、浅いコピーのマージで更新される。
   * @param {T} obj
   */
  const setObject = (obj) => {
    const newState = { ...ref.current, ...obj }
    _setState({ ...newState, ...obj })
    ref.current = newState
  }

  /**
   * Stateオブジェクトのプロパティ階層を指定して、値を更新する。
   * @param {Array | number | keyof T} propKeys
   * @param {*} anyValue
   * @example
   * const sm = useStateManage({ 1: { open: false }, 2: { open: false, expanded: false } })
   * sm.setValue([2, 'open'], true) ← sm[2].open の値が更新される
   *
   */
  const setValue = (propKeys, anyValue) => {
    const copyObj = { ...ref.current }
    if (typeof propKeys === 'string' || propKeys instanceof String || Number.isFinite(propKeys)) {
      if (isObject(anyValue)) {
        copyObj[propKeys] = { ...copyObj[propKeys], ...anyValue }
      } else {
        copyObj[propKeys] = anyValue
      }
      setObject(copyObj)
      return
    }

    let layer = copyObj
    for (let i = 0; i < propKeys.length; i++) {
      const nest = layer[propKeys[i]]
      if (nest === undefined) {
        layer[propKeys[i]] = {}
      }
      if (i === propKeys.length - 1) {
        if (isObject(anyValue)) {
          layer[propKeys[i]] = { ...propKeys[i], ...anyValue }
        } else {
          layer[propKeys[i]] = anyValue
        }
      } else {
        layer = layer[propKeys[i]]
      }
    }
    setObject(copyObj)
  }

  /**
   * ステートを初期オブジェクトにリセットする。
   */
  const reset = () => {
    _setState(initObj)
    ref.current = initObj
  }

  return { state, setValue, setObject, reset, _default }
}
