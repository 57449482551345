import { createContext, useContext, useState, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'
import { isEqual, isEqualDiff } from 'src/util/commons'
import { useUser } from 'src/util/appContext'
import { useApi } from './api'

const MainContext = createContext({})
export const MainProvider = ({ children }) => {
  const _mainRef = useRef({
    // usePageControl 利用
    form: {},
    pageRequest: {
      btn: '',
      pageNo: undefined,
      values: {},
      orderBy: {}
    },
    unusedRequestValues: [],
    sort: { name: '', desc: false },
    searchCb: () => {
      console.error('search not initialized!')
    },
    searchChangedCb: () => {
      console.error('search not initialized!')
    },
    // useMainRef 利用の共用オブジェクト
    values: {},
    callBacks: {}
  })
  // const [mainState, setMainState] = useState(true)
  const value = {
    // mainState,
    // setMainState,
    _mainRef
  }

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}
MainProvider.propTypes = { children: PropTypes.any }

/**
 * MainContext内の共有参照を利用するメソッドを提供する。
 */
export const useMainRef = () => {
  const ctxt = useContext(MainContext)
  const curt = ctxt._mainRef.current

  const method = {
    get: (field) => {
      return curt.values[field]
    },
    set: (field, value) => {
      curt.values[field] = value
    },
    getValues: () => {
      return curt.values
    },
    setValues: (values) => {
      curt.values = values
    },
    setCallBack: (cbName, cb) => {
      curt.callBacks[cbName] = cb
    },
    call: (cbName, args) => {
      const cb = curt.callBacks[cbName]
      if (!cb) {
        console.error(`[useMainRef][${cbName}] is not set CallBack Function`)
        return undefined
      }
      return cb(args)
    },
    /**
     * 値が変更されている場合、参照値を更新する。
     * 更新不要な場合、falseを返す。
     */
    changeValues: (values) => {
      if (isEqual(ctxt._mainRef.current.values, values)) return false
      ctxt._mainRef.current.values = { ...values }
      return true
    }
  }
  return method
}

/**
 * Context内の共有参照を利用したページ制御を提供する。
 * 検索フォームと結果テーブルを分け、フォーム側からの呼び出しを行う目的で作成。
 * @return { object } method
 */
export const usePageControl = () => {
  const ctxt = useContext(MainContext)
  const ref = ctxt._mainRef.current

  const checkFormSearchChenged = (pageRequest) => {
    if (pageRequest.btn === 'search') return false
    const currentVals = ref.form.getValues()
    return !isEqual(currentVals, pageRequest.values)
  }

  const method = {
    sort: ref.sort,

    setMainRefVal: (field, value) => {
      ref.values[field] = value
    },
    getMainRefVal: (field) => {
      return ref.values[field]
    },
    setAfterCall: (afterCb) => {
      ref.afterCb = afterCb
    },

    // 検索テーブル側の初期化で使用。
    initSearch: (searchCb, searchChangedCb) => {
      ref.searchCb = searchCb
      ref.searchChangedCb = searchChangedCb
    },

    // 検索のパラメータに使用しない値を、検索前に設定
    unusedRequestValue: (value) => {
      ref.unusedRequestValues.push(value)
    },

    // Form側の検索コールバック呼び出し
    setFormRequest: (form, _pageRequest) => {
      if (form) ref.form = form
      // searchボタンは値初期化、その他のボタンはマージ（前回の値を保持）
      const newRequest = _pageRequest.btn === 'search' ? _pageRequest : { ...ref.pageRequest, ..._pageRequest }
      ref.pageRequest = newRequest
      method.callSearch(newRequest, ref.searchCb, ref.searchChangedCb)
    },

    // 検索の呼び出し
    callSearch: async (pageRequest, searchCb, searchChangedCb) => {
      if (!pageRequest.btn) return
      if (checkFormSearchChenged(pageRequest)) {
        searchChangedCb()
        return
      }
      if (pageRequest.btn === 'search' && ref.sort.name !== '') {
        ref.sort = { name: '', desc: false }
      }
      const values = { ...pageRequest.values, ...{ orderBy: pageRequest.orderBy } }
      ref.unusedRequestValues.forEach((key) => delete values[key])
      const rv = await searchCb(pageRequest.pageNo, values)
      if (ref.afterCb) ref.afterCb(rv)
    },

    // ソート時のハンドル
    handleSort: (_sort, searchChangedCb) => {
      if (checkFormSearchChenged(ref.pageRequest)) {
        searchChangedCb()
        return
      }

      ref.sort = { ..._sort }
      let _order = _sort.name
      if (_sort.desc) _order += ' desc'
      method.setFormRequest(null, { btn: 'sort', pageNo: 1, orderBy: _order })
    }
  }
  return method
}

// /**
//  * 契約関連の汎用オブジェクト関数群を提供するフック
//  * @param {{
//  *   userRef: boolean
//  * }} opts
//  * @return { object } 契約用フック
//  */
// export const useContract = (opts = {}) => {
//   const [toggle, render] = useState(false)
//   const ref = useMainRef()
//   const request = useApi()
//   let user
//   if (opts.userRef) user = useUser()

//   const groupBy = (array) => {
//     const map = new Map()
//     array.forEach((obj) => {
//       const tmp = map.get(obj.contract_id) ? map.get(obj.contract_id) : []
//       map.set(obj.contract_id, tmp.concat(obj))
//     })
//     return map
//   }

//   const setContractListBase = (rows, renderFlg) => {
//     ref.set('CONTRACT_LIST', rows)
//     ref.set(
//       'CONTRACT_DISP_LIST',
//       rows.map((row) => `${row.contract_id}:${row.contract_name}`)
//     )
//     console.warn('#setContractListBase')
//     if (renderFlg) render(!toggle)
//   }

//   const setAuthGroupListBase = (rows, renderFlg) => {
//     ref.set('AUTH_GROUP_LIST', rows)
//     ref.set('AUTH_GROUP_MAP', groupBy(rows))
//     if (renderFlg) render(!toggle)
//   }

//   const contract = {
//     init: async (renderFlg) => {
//       await request.get('/contractAll', null, ({ body }) => {
//         setContractListBase(body.rows)
//       })
//       await request.get('/authGroup/list', null, ({ body }) => {
//         setAuthGroupListBase(body)
//       })
//       if (renderFlg) render(!toggle)
//     },
//     /**
//      * 契約オブジェクトのリストをセットする。\
//      * 配列のままのリストと、表示用の一覧（:で契約IDと契約名を連結）を保持する。
//      * @param {Array} rows
//      * @param {boolean} renderFlg
//      */
//     setContractList: (rows, renderFlg) => {
//       setContractListBase(rows, renderFlg)
//     },

//     /**
//      * 契約オブジェクトのリスト一覧を取得する。\
//      * 初期化されていない場合、空配列を返す。
//      */
//     getContractList: () => {
//       return ref.get('CONTRACT_LIST') || []
//     },

//     /**
//      * 表示用の契約名（契約ID:契約名）一覧を取得します。\
//      * 初期化されていない場合、空配列を返す。
//      */
//     getContractDispList: () => {
//       const cdl = ref.get('CONTRACT_DISP_LIST') || []
//       console.warn('#getContractDispList#', cdl)
//       return ref.get('CONTRACT_DISP_LIST') || []
//     },

//     /**
//      * 表示用の契約名（契約ID:契約名）から契約ID部分を取り出し返す。\
//      * @param {string} contract_disp_name
//      * @return { string | null}
//      */
//     cutContractDisp: (contract_disp_name) => {
//       if (!contract_disp_name) return null
//       const posision = contract_disp_name.indexOf(':')
//       if (posision < 0) return null
//       return contract_disp_name.substring(0, posision)
//     },

//     /**
//      * 権限グループリストをセットする。
//      * @param {Array} rows
//      * @param {boolean} renderFlg
//      */
//     setAuthGroupList: (rows, renderFlg) => {
//       setAuthGroupListBase(rows, renderFlg)
//       console.log('#setAuthGroupListBase', renderFlg)
//     },

//     /**
//      * 権限グループリストを取得する。\
//      * 契約IDを指定した場合、その契約分を抽出します。
//      * @param {number?} contract_id
//      */
//     getAuthGroupList: (contract_id) => {
//       if (contract_id === undefined) return ref.get('AUTH_GROUP_LIST') || []
//       if (contract_id === null || contract_id === '') return []
//       const map = ref.get('AUTH_GROUP_MAP')
//       return map?.get(Number(contract_id)) || []
//     }
//   }

//   return contract
// }
