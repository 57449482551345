import { Box, Button, Card, CardContent, Divider, Grid, MenuItem } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { HeaderBox, TextField, ContractAutocomplete } from 'src/components'
import { usePageControl, useContract } from 'src/util/'

export const OrderSearch = () => {
  console.log('OrderSearch')
  const pc = usePageControl()
  const contract = useContract()

  const form = useForm({
    defaultValues: {
      contract_id: '',
      account_id: '',
      status: '',
      expires: '',
      issued_subject_dn: '',
      identifier_value: ''
    }
  })

  const onSubmit = (values) => {
    pc.unusedRequestValue('title')
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(decodeURI(window.location.search))
    const account_id = searchParams.get('account_id')
    form.setValue('account_id', account_id || '')
    form.handleSubmit(onSubmit)()
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="オーダー 検索" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {contract.isSuper() && (
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <ContractAutocomplete form={form} contract={contract} label="契約" />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={contract.isSuper() ? 5 : 8}>
              <TextField form={form} name="account_id" label="アカウントID" inputProps={{ maxLength: 32 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
              <TextField
                form={form}
                name="expires"
                label="注文期限（以降）"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
              <TextField select form={form} name="status" label="ステータス">
                <MenuItem key={0} value="">
                  すべて
                </MenuItem>
                <MenuItem key={1} value="pending">
                  pending
                </MenuItem>
                <MenuItem key={2} value="invalid">
                  invalid
                </MenuItem>
                <MenuItem key={3} value="ready">
                  ready
                </MenuItem>
                <MenuItem key={4} value="processing">
                  processing
                </MenuItem>
                <MenuItem key={5} value="valid">
                  valid
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={contract.isSuper() ? 5 : 6}>
              <TextField
                form={form}
                name="issued_subject_dn"
                label="発行済サブジェクトDN"
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={contract.isSuper() ? 5 : 6}>
              <TextField form={form} name="identifier_value" label="ドメイン" inputProps={{ maxLength: 64 }} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, px: 2 }}>
          <Button type="submit" color="primary" variant="contained">
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default OrderSearch
