import { Card, Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import { FooterBox, HeaderBox, Loading, Pagination, SortCell, StyledCell } from 'src/components'
import { useApi, useStateManage, usePageControl } from 'src/util/'
import OrderTableBody from './OrderTableBody'

const OrderTable = () => {
  console.log('OrderTable')
  const request = useApi()
  const pc = usePageControl()
  const { state, setObject } = useStateManage({
    dataTotal: 0,
    pageTotal: 0,
    pageNo: 0,
    ejbca_id: undefined,
    list: [],
    retryDialog: false
  })

  const search = async (pageNo, values) => {
    request.setOption({ loading: 'load1' })
    values.pageNo = pageNo
    request.get('/acme/order/page', values, ({ body }) => {
      setObject({
        dataTotal: body.page.dataTotal,
        pageTotal: body.page.pageTotal,
        pageNo,
        ejbca_id: values.ejbca_id,
        list: [...body.rows]
      })
    })
  }
  useEffect(() => pc.initSearch(search, () => setObject({ retryDialog: true })), [])

  const _handleSort = (_sort) => {
    if (state.dataTotal === 0) return
    pc.handleSort(_sort, () => setObject({ retryDialog: true }))
  }

  return (
    <Card>
      <Loading name="load1" sx={{ top: '10px' }} />
      <HeaderBox title="オーダー 一覧">{state.dataTotal !== 0 && '計' + state.dataTotal + '件'}</HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCell sx={{ minWidth: 90 }} name="t1.order_id" sort={pc.sort} onClick={_handleSort}>
                No
              </SortCell>
              <SortCell name="t6.contract_id" sort={pc.sort} onClick={_handleSort}>
                契約ID
              </SortCell>
              <SortCell sx={{ minWidth: 130 }} name="t1.account_id" sort={pc.sort} onClick={_handleSort}>
                アカウントID
              </SortCell>
              <SortCell sx={{ minWidth: 90 }} name="t1.expires" sort={pc.sort} onClick={_handleSort}>
                注文期限
              </SortCell>
              <SortCell sx={{ minWidth: 90 }} name="t1.status" sort={pc.sort} onClick={_handleSort}>
                ステータス
              </SortCell>
              <StyledCell sx={{ minWidth: 160 }}>発行済サブジェクトDN</StyledCell>
              <StyledCell sx={{ minWidth: 45 }}>DL数</StyledCell>
              <StyledCell sx={{ width: 24 }} />
            </TableRow>
          </TableHead>
          <OrderTableBody ejbca_id={state.ejbca_id} list={state.list} />
        </Table>
      </TableContainer>
      <FooterBox sx={{ justifyContent: 'center' }}>
        <Pagination
          state={state}
          setFormRequest={pc.setFormRequest}
          closeRetryDialog={() => setObject({ retryDialog: false })}
        />
      </FooterBox>
    </Card>
  )
}

export default OrderTable
