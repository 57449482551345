import { Container, Grid } from '@mui/material'
import UserTable from 'src/contents/userManage/UserTable'
import UserSearch from 'src/contents/userManage/UserSearch'
import { MainProvider, useInit } from 'src/util/'
import { MainContent } from 'src/components'

const UserManage = () => {
  console.log('UserManage')
  useInit().setContract()
  useInit().setPermissionGroup()

  return (
    <MainContent title="UserManage | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserSearch />
            </Grid>
            <Grid item xs={12}>
              <UserTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default UserManage
