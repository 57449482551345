import {
  DonutLarge as ChartIcon,
  Person as UserIcon,
  Settings as SettingsIcon,
  Public as PublicIcon,
  EnhancedEncryption as EnhancedEncryptionIcon,
  LocalPolice as LocalPoliceIcon,
  GppGood as GppGoodIcon,
  ManageAccounts as ManageAccountsIcon,
  MiscellaneousServices as MiscellaneousServicesIcon,
  Policy as PolicyIcon,
  Security as SecurityIcon,
  Storage as StorageIcon,
  Token as TokenIcon,
  DeviceHub as DeviceHubIcon,
  Description as DescriptionIcon,
  Task as TaskIcon,
  CardMembership as CardMembershipIcon
} from '@mui/icons-material'
// import {
//   BarChart as BarChartIcon,
//   ShoppingBag as ShoppingBagIcon,
//   Users as UsersIcon,
// } from 'react-feather'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useGlobal } from './util'

export const MENU_ROUTE_PUBLIC = [
  {
    title: '公開情報',
    path: '/app/publicInfo',
    icon: PublicIcon,
    rout: 'PublicInfo'
  }
]

export const MENU_ROUTE_LOGIN = [
  {
    title: '公開情報',
    path: '/app/publicInfo',
    icon: PublicIcon,
    rout: 'PublicInfo'
  },
  {
    title: 'URL監視システム',
    icon: ChartIcon
  },
  {
    title: 'URL監視',
    path: '/app/urlMonitor',
    icon: ChartIcon,
    rout: 'UrlMonitor'
  },
  {
    title: 'URL監視設定',
    path: '/app/urlMoniSetting',
    icon: SettingsIcon,
    rout: 'UrlMonitorSetting'
  },
  {
    title: '個人アカウント',
    path: '/app/myAccount',
    icon: UserIcon,
    rout: 'Account'
  },
  {
    title: 'システム管理',
    icon: SecurityIcon
  },
  {
    title: '権限管理',
    path: '/app/permissionManage',
    icon: SecurityIcon,
    rout: 'PermissionManage'
  },
  {
    title: 'ユーザ管理',
    path: '/app/userManage',
    icon: SecurityIcon,
    rout: 'UserManage'
  },
  {
    title: '契約管理',
    path: '/app/contractManage',
    icon: SecurityIcon,
    rout: 'ContractManage'
  },
  {
    title: 'リモートサーバ管理',
    icon: StorageIcon
  },
  {
    title: 'リモート接続',
    path: '/app/remotoConect',
    icon: DeviceHubIcon,
    rout: 'ServerConect'
  },
  {
    title: 'リモートログイン履歴',
    path: '/app/remotoLoginHistory',
    icon: DescriptionIcon,
    rout: 'ServerInfo'
  },
  {
    title: 'バッチ管理',
    path: '/app/batchManage',
    icon: TaskIcon,
    rout: 'BatchManage'
  },
  {
    title: 'EJBCA管理',
    icon: TokenIcon
  },
  {
    title: 'EJBCAデータ',
    path: '/app/ejbcaData',
    icon: DeviceHubIcon,
    rout: 'EjbcaData'
  },
  // {
  //   title: '証明書一覧', path: '/app/ejbcaData/certList', icon: DeviceHubIcon, rout: 'EjbcaList'
  // },
  {
    title: 'EJBCAログ履歴',
    path: '/app/ejbcaLogHistory',
    icon: DescriptionIcon,
    rout: 'EjbcaLogHistory'
  },
  {
    title: 'ACME管理',
    icon: CardMembershipIcon
  },
  {
    title: 'ACMEアカウント',
    path: '/app/acmeAccount',
    icon: DeviceHubIcon,
    rout: 'AcmeAccount'
  },
  {
    title: 'ACMEオーダー',
    path: '/app/acmeOrder',
    icon: DeviceHubIcon,
    rout: 'AcmeOrder'
  },
  {
    title: 'SCI-WS',
    path: '/app/sciWs',
    icon: DeviceHubIcon,
    rout: 'SciWs'
  }
]

/**
 * ノードメニューのプロパティに、MENU_ROUTE_LOGIN定義のアイコンやrout情報を設定する。
 * MENU_ROUTE_LOGIN定義と差異がある場合、ノードから外される。
 */
function recursionRouteInfo(node, homePath, root) {
  if (!root) {
    const nodeMenu = MENU_ROUTE_LOGIN.find((v) => (v.path && v.path === node.path) || v.title === node.title)
    if (!nodeMenu) {
      node.delete = true
      return
    }
    if (node.path === homePath) {
      console.log('node.path === homePath', node.path)
      node.loginOpen = true
    }
    node.icon = nodeMenu.icon
    node.rout = nodeMenu.rout
  }

  if (!node.children) return
  for (let i = 0; i < node.children.length; i++) {
    const _node = node.children[i]
    recursionRouteInfo(_node, homePath)
    if (_node.delete) {
      node.children.some((v, j) => {
        if (v.app_menu_id !== _node.app_menu_id) return false
        // 削除ノードを取り除く
        node.children.splice(j, 1)
        i--
        return true
      })
    }
    if (_node.loginOpen) node.loginOpen = true
  }
}

export function useMenuRout() {
  const gRef = useGlobal()

  const initializePermission = (permission_group_id, menu, allowedMenuPaths, lastLocation) => {
    if (!menu && !allowedMenuPaths) {
      menu = MENU_ROUTE_PUBLIC
      allowedMenuPaths = MENU_ROUTE_PUBLIC
    }

    const dispMenus = []
    if (lastLocation) {
      if (lastLocation.startsWith('/app/ejbcaData/')) lastLocation = '/app/ejbcaData'
      if (lastLocation.startsWith('/app/batchManage/')) lastLocation = '/app/batchManage'
    }

    recursionRouteInfo({ children: menu }, lastLocation, true)
    menu.forEach((item) => dispMenus.push(item))
    gRef.setPermissionGroupId(permission_group_id)
    gRef.setDispMenus(dispMenus)

    const allowedMenuPathsObj = {}
    allowedMenuPaths.forEach((path) => {
      allowedMenuPathsObj[path] = true
    })

    // render('allowedMenuPaths', allowedMenuPathsObj)
    gRef.renderMenuPaths(allowedMenuPathsObj)
  }

  return initializePermission
}
